import React, { useEffect, useState } from "react"
import { changeBreadcrumbs, changeTitle } from "../utils/page"
import IlsBeranda from "../assets/img/illustrations/man-with-laptop-light.png"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { request } from "../utils/request"
import { refreshDashboard } from "../utils/config"
import { dateTime, strtotime } from "../utils/formatter"
import HeadTable from "../components/Table/HeadTable"

const Beranda = (props) => {
    const { auth } = props

    const [data, setData] = useState({
        total: {
            jumlahKursusPelajar: 0,
            soalUjian: 0,
            kategoriSoal: 0,
            sesiUjian: 0,
            hasilSeleksi: 0,
            daftarUlang: 0,
            pesertaTanpaSesi: 0,
            ujianPeserta: 0
        },
        list: {
            ujianPerSesi: []
        }
    })

    useEffect(() => {
        const titlePage = "Beranda"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)
    }, [])

    useEffect(() => { 
        actionFetchDashboard()

        if (refreshDashboard === true) {
            let intervalId = setInterval(() => actionFetchDashboard(), 30000)

            return () => clearInterval(intervalId)
        }
    }, [auth?.user])

    const actionFetchDashboard = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${auth?.accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_DASHBOARD + '/main', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            }
        } catch (error) { }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-12 mb-2 order-0">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card mb-4">
                                <div className="d-flex align-items-end row">
                                    <div className="col-sm-7">
                                        <div className="card-body">
                                            <h5 className="card-title text-primary">Selamat Datang{" " + auth?.user?.name}! 🎉</h5>
                                            <p className="mb-4">Kelompok {data?.namaKelompok || "-"}</p>
                                            {
                                                data?.total?.jumlahKursusPelajar > 0 ?
                                                    <Link to={"/kursus-diikuti"} className="btn btn-sm btn-label-primary">Lihat Kursus</Link>
                                                    :
                                                    <Link to={"/kursus"} className="btn btn-sm btn-label-primary">Lihat Kursus</Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-5 text-center text-sm-left">
                                        <div className="card-body pb-0 px-0 px-md-4">
                                            <img src={IlsBeranda} height="140" alt="View Badge User" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-md-6 col-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className="flex-shrink-0">
                                                    <i className="bx bx-category" style={{ fontSize: "3.2rem", color: "#20827b" }}></i>
                                                </div>
                                            </div>
                                            <span className="d-block">Kursus</span>
                                            <h4 className="card-title mb-1">{data?.total?.jumlahKursus || 0}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className="flex-shrink-0">
                                                    <i className="bx bx-bookmark-alt" style={{ fontSize: "3.2rem", color: "#ff6600" }}></i>
                                                </div>
                                            </div>
                                            <span className="d-block">Kursus Diikuti</span>
                                            <h4 className="card-title mb-1">{data?.total?.jumlahKursusPelajar || 0}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

export default withRouter(connect(mapStateToProps, {})(Beranda))