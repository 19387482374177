import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop'
import { useIdleTimer } from 'react-idle-timer'
import { alert, confirm } from '../../../utils/alert'
import { connect } from 'react-redux'
import { setExpired, actionRefreshToken } from '../../../store/auth/actions'
import { actionFetchNotifikasi, actionFetchNotifikasiSuccess } from '../../../store/notifikasi/actions'
import { authExpiredTime, refreshNotif } from '../../../utils/config'
import UseScript from '../../UseSript'
import SvgLogo from "../../../assets/svg/logo.svg"
import Menu from '../Menu'
import Breadcrumbs from '../Breadcrumbs'
import Navbar from '../Navbar'
import { APP_NAME } from '../../../constants'
import Footer from '../Footer'
import { pushUrl } from '../../../utils/url'

const AuthLayout = (props) => {
    const { children, auth, setExpired, actionRefreshToken, actionFetchNotifikasi, actionFetchNotifikasiSuccess, notifikasi } = props

    const [isCounting, setIsCounting] = useState(false)
    const [isExpired, setIsExpired] = useState(false)
    const [expiredTime, setExpiredTime] = useState(authExpiredTime)

    const handleOnIdle = () => {
        setIsExpired(true)
    }

    const addExpired = () => {
        let now = parseInt((new Date).getTime() / 1000)
        setExpired(now + authExpiredTime)
    }

    const handleOnAction = () => {
        if (isExpired === false) {
            addExpired()
        }
    }

    const { start } = useIdleTimer({
        startManually: true,
        timeout: 1000 * expiredTime,
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        debounce: 250
    })

    useEffect(() => {
        actionFetchNotifikasi({ accessToken: auth?.accessToken })

        if (refreshNotif === true) {
            let intervalId = setInterval(() => actionFetchNotifikasi({ accessToken: auth?.accessToken }), 15000)

            return () => clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        let now = parseInt((new Date).getTime() / 1000)
        let sisaWaktu = auth?.expiredAt - now
        setExpiredTime(sisaWaktu)

        if (sisaWaktu < 0) {
            setIsExpired(true)
        } else {
            start()
            setIsCounting(true)
        }
    }, [auth?.expiredAt])

    useEffect(() => {
        if (isExpired === true) {
            if (isCounting === true) {
                askLogout()
            } else {
                logout()
            }
        }
    }, [isExpired, isCounting])

    const askLogout = async () => {
        let isConfirm = await confirm({
            title: "Sesi anda telah berakhir",
            html: "<p>Tetap terhubung?</p>",
        })

        if (isConfirm === true) {
            setIsExpired(false)
            actionRefreshToken(auth?.accessToken)
        } else {
            pushUrl("/sign-out")
        }
    }

    const logout = async () => {
        await alert({
            title: "Sesi anda telah berakhir",
            html: "Silahkan login kembali",
        })

        pushUrl("/sign-out")
    }

    return <React.Fragment>
        <ScrollToTop />

        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                    <div className="app-brand demo ">
                        <Link to={"/beranda"} className="app-brand-link">
                            <span className="app-brand-logo demo">
                                <img src={SvgLogo} alt="Logo" className="logo-app" />
                            </span>
                            <span className="app-brand-text demo menu-text fw-bolder ms-1 fs-5 main-logo-text text-primary">{APP_NAME}</span>
                        </Link>
                        <a href="/#" onClick={(e) => e.preventDefault()} className="layout-menu-toggle menu-link text-large ms-auto">
                            <i className="bx bx-chevron-left bx-sm align-middle"></i>
                        </a>
                    </div>

                    <div className="menu-inner-shadow"></div>

                    <Menu />
                </aside>

                <div className="layout-page">
                    <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
                        id="layout-navbar">
                        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
                            <a className="nav-item nav-link px-0 me-xl-4" href="/#" onClick={(e) => e.preventDefault()}>
                                <i className="bx bx-menu bx-sm"></i>
                            </a>
                        </div>

                        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                            <div className="navbar-nav align-items-center">
                                <Breadcrumbs />
                            </div>

                            <Navbar user={auth?.user} accessToken={auth?.accessToken} onClickSuccess={actionFetchNotifikasiSuccess} notificationList={notifikasi?.data} />
                        </div>
                    </nav>

                    <div className="content-wrapper">
                        {children}

                        {/* <Footer /> */}
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>

            <div className="layout-overlay layout-menu-toggle"></div>

            <div className="drag-target"></div>
        </div>

        <UseScript src={"/assets/vendor/libs/jquery/jquery.js"} />
        <UseScript src={"/assets/vendor/libs/popper/popper.js"} />
        <UseScript src={"/assets/vendor/js/bootstrap.js"} />
        <UseScript src={"/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"} />

        <UseScript src={"/assets/vendor/libs/bootstrap-datepicker/bootstrap-datepicker.js"} />

        <UseScript src={"/assets/vendor/js/menu.js"} />
        <UseScript src={"/assets/js/main.js"} />
    </React.Fragment>
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        notifikasi: state.notifikasi
    }
}

export default withRouter(connect(mapStateToProps, { setExpired, actionRefreshToken, actionFetchNotifikasi, actionFetchNotifikasiSuccess })(AuthLayout)) 