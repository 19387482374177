import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionDeleteKursus, actionResetDeleteKursus } from "../../store/kursus/actions"
import { request } from "../../utils/request"
import { alert, confirm } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../constants"

const KursusDetail = (props) => {
    const { actionResetDeleteKursus, accessToken, match: { params: { id } }, history: { location: { state } }, del } = props
    const [data, setData] = useState(null)
    const [noData, setNoData] = useState(false)
    const [submit, setSubmit] = useState(false)

    const [diikuti, setDiikuti] = useState(null)
    const [modulAktif, setModulAktif] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const titlePage = "Detail Kursus"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (state) {
            setData(state)
            setTimeout(() => getData(), 300)
        } else {
            getData()
        }

        actionResetDeleteKursus()

        return () => actionResetDeleteKursus()
    }, [])

    useEffect(() => {
        if (submit === true) {
            if (del?.success === true) {
                alert({ title: "Berhasil hapus data" })
                pushUrl("/kursus")
            } else if (del?.success === false) {
                alert({ title: "Gagal hapus data" })
            }
        }
    }, [del?.success, submit])

    const getData = async () => {
        setLoading(true)

        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KURSUS + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setLoading(false)
                setData(res?.result)
                setDiikuti(res?.result?.diikuti === true)
            } else {
                setLoading(false)
                setNoData(true)
            }
        } catch (error) {
            setLoading(false)
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const ikuti = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KURSUS + '/ikuti/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setDiikuti(true)
                getData()
                alert({ title: "Berhasil", html: "Berhasil mengikuti", icon: "success" })
            } else {
                alert({ title: "Gagal", html: "Gagal mengikuti" })
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal mengikuti" })
        }
    }

    const onClickBtnIkuti = (e) => {
        ikuti()
        e.preventDefault()
    }

    const headerDetail = () => {
        return <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="user-profile-header-banner">
                            <img src={process.env.REACT_APP_PENGAJAR_SERVICE + '/uploads/kursus/banner/' + data?.banner} alt={data?.nama} className="rounded-top" />
                        </div>
                        <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                            <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                <img src={process.env.REACT_APP_PENGAJAR_SERVICE + '/uploads/kursus/logo/' + data?.logo} alt={data?.nama} className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img" />
                            </div>
                            <div className="flex-grow-1 mt-3 mt-sm-5">
                                <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                    <div className="user-profile-info">
                                        <h4>{data?.nama}</h4>

                                        <p className="card-text">
                                            <small className="text-muted">Terakhir diperbarui oleh {data?.updated_by?.name || "-"}</small>
                                            {/* <br />
                                            <small className="text-muted">{data?.updated_at}</small> */}
                                        </p>
                                    </div>
                                    {
                                        diikuti === false ?
                                            <button type="button" onClick={onClickBtnIkuti} className="btn btn-secondary text-nowrap">
                                                Ikuti
                                            </button>
                                            : null
                                    }
                                    {
                                        diikuti === true ?
                                            <button type="button" className="btn btn-primary text-nowrap">
                                                <i className="bx bx-check me-1"></i>Diikuti
                                            </button>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const gantiModul = (key) => {
        setModulAktif(key)
    }

    const viewPertemuanTidakAda = () => {
        return <div className="card mt-1 mb-3">
            <div className="card-body">
                <p className="card-text">Pertemuan tidak tersedia</p>
            </div>
        </div>
    }

    const viewModulTidakAda = () => {
        return <div className="card mt-1 mb-3">
            <div className="card-body">
                <p className="card-text">Modul tidak tersedia</p>
            </div>
        </div>
    }

    const getPct = (val1, val2) => {
        if (val1 && val2) {
            return parseInt((val1 / val2) * 100);
        }

        return 0
    }

    const bodyDetail = () => {
        return <React.Fragment>
            {
                diikuti === false ? <div className="alert alert-primary" role="alert">
                    Ikuti kursus untuk memulai
                </div>
                    : null
            }

            <div className="row mb-2">
                <div className="col-xl-4 col-lg-5 col-md-5">
                    <div>
                        <small className="text-muted text-uppercase">Tentang Kursus</small>
                        <div className="card mt-1 mb-3">
                            <div className="card-body">
                                <span className="card-text mb-2" dangerouslySetInnerHTML={{ __html: data?.keterangan }}></span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <small className="text-muted text-uppercase">Rincian</small>
                        <div className="card mt-1 mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <ul className="list-unstyled mb-2">
                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Modul:</span> <span className="text-muted">{data?.rincian?.modul?.jumlahSemua || 0}</span></li>
                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Pertemuan:</span> <span className="text-muted">{data?.rincian?.pertemuan?.jumlahSemua || 0}</span></li>
                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Jobsheet:</span> <span className="text-muted">{data?.rincian?.jobsheet?.jumlahSemua || 0}</span></li>
                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Gambar:</span> <span className="text-muted">{data?.rincian?.gambar?.jumlahSemua || 0}</span></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul className="list-unstyled mb-2">
                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Video:</span> <span className="text-muted">{data?.rincian?.video?.jumlahSemua || 0}</span></li>
                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Quiz:</span> <span className="text-muted">{data?.rincian?.quiz?.jumlahSemua || 0}</span></li>
                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Tugas:</span> <span className="text-muted">{data?.rincian?.laporan?.jumlahSemua || 0}</span></li>
                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Quiz Awal:</span> <span className="text-muted">{data?.rincian?.quizAwal?.jumlahSemua || 0}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <small className="text-muted text-uppercase">Progress</small>
                        <div className="card mt-1 mb-3">
                            <div className="card-body">
                                {
                                    data?.rincian?.modul?.jumlahSemua > 0 ?
                                        <div className="mb-2">
                                            <small className="text-muted">Modul </small><small>{data?.rincian?.modul?.jumlahSelesai || 0}/{data?.rincian?.modul?.jumlahSemua || 0}</small>
                                            <div className="progress mt-1">
                                                <div className={"progress-bar " + (getPct(data?.rincian?.modul?.jumlahSelesai, data?.rincian?.modul?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(data?.rincian?.modul?.jumlahSelesai, data?.rincian?.modul?.jumlahSemua) + "%" }} aria-valuenow={getPct(data?.rincian?.modul?.jumlahSelesai, data?.rincian?.modul?.jumlahSemua)} aria-valuemin="0" aria-valuemax="100">{getPct(data?.rincian?.modul?.jumlahSelesai, data?.rincian?.modul?.jumlahSemua)}%</div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    data?.rincian?.pertemuan?.jumlahSemua > 0 ?
                                        <div className="mb-2">
                                            <small className="text-muted">Pertemuan </small><small>{data?.rincian?.pertemuan?.jumlahSelesai || 0}/{data?.rincian?.pertemuan?.jumlahSemua || 0}</small>
                                            <div className="progress mt-1">
                                                <div className={"progress-bar " + (getPct(data?.rincian?.pertemuan?.jumlahSelesai, data?.rincian?.pertemuan?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(data?.rincian?.pertemuan?.jumlahSelesai, data?.rincian?.pertemuan?.jumlahSemua) + "%" }} aria-valuenow={getPct(data?.rincian?.pertemuan?.jumlahSelesai, data?.rincian?.pertemuan?.jumlahSemua)} aria-valuemin="0" aria-valuemax="100">{getPct(data?.rincian?.pertemuan?.jumlahSelesai, data?.rincian?.pertemuan?.jumlahSemua)}%</div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    data?.rincian?.jobsheet?.jumlahSemua > 0 ?
                                        <div className="mb-2">
                                            <small className="text-muted">Jobsheet </small><small>{data?.rincian?.jobsheet?.jumlahSelesai || 0}/{data?.rincian?.jobsheet?.jumlahSemua || 0}</small>
                                            <div className="progress mt-1">
                                                <div className={"progress-bar " + (getPct(data?.rincian?.jobsheet?.jumlahSelesai, data?.rincian?.jobsheet?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(data?.rincian?.jobsheet?.jumlahSelesai, data?.rincian?.jobsheet?.jumlahSemua) + "%" }} aria-valuenow={getPct(data?.rincian?.jobsheet?.jumlahSelesai, data?.rincian?.jobsheet?.jumlahSemua)} aria-valuemin="0" aria-valuemax="100">{getPct(data?.rincian?.jobsheet?.jumlahSelesai, data?.rincian?.jobsheet?.jumlahSemua)}%</div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    data?.rincian?.gambar?.jumlahSemua > 0 ?
                                        <div className="mb-2">
                                            <small className="text-muted">Gambar </small><small>{data?.rincian?.gambar?.jumlahSelesai || 0}/{data?.rincian?.gambar?.jumlahSemua || 0}</small>
                                            <div className="progress mt-1">
                                                <div className={"progress-bar " + (getPct(data?.rincian?.gambar?.jumlahSelesai, data?.rincian?.gambar?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(data?.rincian?.gambar?.jumlahSelesai, data?.rincian?.gambar?.jumlahSemua) + "%" }} aria-valuenow={getPct(data?.rincian?.gambar?.jumlahSelesai, data?.rincian?.gambar?.jumlahSemua)} aria-valuemin="0" aria-valuemax="100">{getPct(data?.rincian?.gambar?.jumlahSelesai, data?.rincian?.gambar?.jumlahSemua)}%</div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    data?.rincian?.video?.jumlahSemua > 0 ?
                                        <div className="mb-2">
                                            <small className="text-muted">Video </small><small>{data?.rincian?.video?.jumlahSelesai || 0}/{data?.rincian?.video?.jumlahSemua || 0}</small>
                                            <div className="progress mt-1">
                                                <div className={"progress-bar " + (getPct(data?.rincian?.video?.jumlahSelesai, data?.rincian?.video?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(data?.rincian?.video?.jumlahSelesai, data?.rincian?.video?.jumlahSemua) + "%" }} aria-valuenow={getPct(data?.rincian?.video?.jumlahSelesai, data?.rincian?.video?.jumlahSemua)} aria-valuemin="0" aria-valuemax="100">{getPct(data?.rincian?.video?.jumlahSelesai, data?.rincian?.video?.jumlahSemua)}%</div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    data?.rincian?.quiz?.jumlahSemua > 0 ?
                                        <div className="mb-2">
                                            <small className="text-muted">Quiz </small><small>{data?.rincian?.quiz?.jumlahSelesai || 0}/{data?.rincian?.quiz?.jumlahSemua || 0}</small>
                                            <div className="progress mt-1">
                                                <div className={"progress-bar " + (getPct(data?.rincian?.quiz?.jumlahSelesai, data?.rincian?.quiz?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(data?.rincian?.quiz?.jumlahSelesai, data?.rincian?.quiz?.jumlahSemua) + "%" }} aria-valuenow={getPct(data?.rincian?.quiz?.jumlahSelesai, data?.rincian?.quiz?.jumlahSemua)} aria-valuemin="0" aria-valuemax="100">{getPct(data?.rincian?.quiz?.jumlahSelesai, data?.rincian?.quiz?.jumlahSemua)}%</div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    data?.rincian?.laporan?.jumlahSemua > 0 ?
                                        <div className="mb-2">
                                            <small className="text-muted">Tugas </small><small>{data?.rincian?.laporan?.jumlahSelesai || 0}/{data?.rincian?.laporan?.jumlahSemua || 0}</small>
                                            <div className="progress mt-1">
                                                <div className={"progress-bar " + (getPct(data?.rincian?.laporan?.jumlahSelesai, data?.rincian?.laporan?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(data?.rincian?.laporan?.jumlahSelesai, data?.rincian?.laporan?.jumlahSemua) + "%" }} aria-valuenow={getPct(data?.rincian?.laporan?.jumlahSelesai, data?.rincian?.laporan?.jumlahSemua)} aria-valuemin="0" aria-valuemax="100">{getPct(data?.rincian?.laporan?.jumlahSelesai, data?.rincian?.laporan?.jumlahSemua)}%</div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    data?.rincian?.quizAwal?.jumlahSemua > 0 ?
                                        <div className="mb-2">
                                            <small className="text-muted">Quiz Awal </small><small>{data?.rincian?.quizAwal?.jumlahSelesai || 0}/{data?.rincian?.quizAwal?.jumlahSemua || 0}</small>
                                            <div className="progress mt-1">
                                                <div className={"progress-bar " + (getPct(data?.rincian?.quizAwal?.jumlahSelesai, data?.rincian?.quizAwal?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(data?.rincian?.quizAwal?.jumlahSelesai, data?.rincian?.quizAwal?.jumlahSemua) + "%" }} aria-valuenow={getPct(data?.rincian?.quizAwal?.jumlahSelesai, data?.rincian?.quizAwal?.jumlahSemua)} aria-valuemin="0" aria-valuemax="100">{getPct(data?.rincian?.quizAwal?.jumlahSelesai, data?.rincian?.quizAwal?.jumlahSemua)}%</div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-7 col-md-7">
                    <div>
                        <small className="text-muted text-uppercase">Modul</small>
                        {
                            data?.modul?.length > 0 ?
                                <div className="nav-align-top mt-1 mb-3">
                                    <ul className="kursus nav nav-tabs pb-1" role="tablist">
                                        {
                                            data?.modul?.map((val, key) => {
                                                return <li key={key} className="nav-item" role="presentation">
                                                    <button onClick={() => gantiModul(key)} type="button" className={"nav-link " + (modulAktif === key ? "active" : "")} tabIndex="-1">
                                                        {
                                                            val?.selesai === true ?
                                                                <i className="bx bx-check fw-semibold me-2"></i>
                                                                : null
                                                        }
                                                        {val?.nama}
                                                    </button>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                                : viewModulTidakAda()
                        }
                    </div>

                    {
                        data?.modul?.length > 0 ?
                            data?.modul[modulAktif]?.akses === false && data?.modul[modulAktif]?.pesan ?
                                <div className="alert alert-primary" role="alert">
                                    {data?.modul[modulAktif]?.pesan}
                                </div>
                                : null
                            : null
                    }

                    <div>
                        <small className="text-muted text-uppercase">Pertemuan</small>
                        {
                            data?.modul?.length > 0 ?
                                data?.modul[modulAktif]?.pertemuan?.length > 0 ?
                                    data?.modul[modulAktif]?.pertemuan?.map((val, key) => {
                                        return <div key={key} className="card mt-1 mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title">{val?.nama}</h5>
                                                {/* <p className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan }}></p> */}
                                                <p className="card-text">
                                                    <small className="text-muted">Terakhir diperbarui oleh {val?.updated_by?.name || "-"}</small>
                                                    {/* <br />
                                                    <small className="text-muted">{val?.updated_at}</small> */}
                                                </p>

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <ul className="list-unstyled mb-2">
                                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Jobsheet:</span> <span className="text-muted">{val?.total?.jobsheet || 0}</span></li>
                                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Gambar:</span> <span className="text-muted">{val?.total?.gambar || 0}</span></li>
                                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Video:</span> <span className="text-muted">{val?.total?.video || 0}</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <ul className="list-unstyled mb-2">
                                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Quiz:</span> <span className="text-muted">{val?.total?.quiz || 0}</span></li>
                                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Tugas:</span> <span className="text-muted">{val?.total?.laporan || 0}</span></li>
                                                            <li className="d-flex align-items-center mb-1"><i className="bx bx-chevron-right fw-semibold"></i><span className="mx-2 text-muted">Quiz Awal:</span> <span className="text-muted">{val?.total?.quizAwal || 0}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                {
                                                    val?.pesan ?
                                                        <p className="card-text">
                                                            <small className="text-danger">{val?.pesan}</small>
                                                        </p>
                                                        :
                                                        null
                                                }

                                                {
                                                    val?.akses === true ?
                                                        <Link to={{
                                                            pathname: process.env.REACT_APP_SUBDIR + "/kursus/detail/" + id + "/pertemuan/" + val?.id
                                                        }} className="btn btn-primary btn-sm mt-2">Detail</Link>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    })
                                    : viewPertemuanTidakAda()
                                : viewPertemuanTidakAda()
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row mb-2">
                <div className="col-lg-4">
                    <Link to={process.env.REACT_APP_SUBDIR + "/kursus"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                </div>
                <div className="col-lg-8 text-end"></div>
            </div>

            {
                loading === true ?
                    <p className="text-center mt-3">
                        <span className="spinner-border ms-2" role="status" aria-hidden="true" style={{ borderColor: PRIMARY_COLOR, borderRightColor: SECONDARY_COLOR, width: "1.5rem", height: "1.5rem" }}></span>
                    </p>
                    :
                    <React.Fragment>
                        {headerDetail()}
                        {bodyDetail()}
                    </React.Fragment>
            }
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, kursus: { del } } = state

    return { accessToken, del }
}

export default withRouter(connect(mapStateToProps, { actionDeleteKursus, actionResetDeleteKursus })(KursusDetail))