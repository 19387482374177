import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../../utils/page"
import { actionDeleteKursus, actionResetDeleteKursus } from "../../../store/kursus/actions"
import { request } from "../../../utils/request"
import { alert, confirm } from "../../../utils/alert"
import { pushUrl } from "../../../utils/url"
import Modal from "../../../components/Modal"
import PDFViewer from "../../../components/PDFViewer"
import VideoPlayer from "../../../components/VideoPlayer"
import FileInput from "../../../components/Form/FileInput"
import { closeModal } from "../../../utils/modal"
import { asTimeWithLabel, dateTime } from "../../../utils/formatter"

const initialPayload = {
    file: {
        name: "",
        base64: "",
        size: "",
        extension: ""
    }
}

const KursusPertemuanDetail = (props) => {
    const { actionDeleteKursus, actionResetDeleteKursus, accessToken, match: { params: { id, pertemuanId } }, history: { location: { state } }, del, user } = props
    const [data, setData] = useState(null)
    const [progress, setProgress] = useState(null)
    const [noData, setNoData] = useState(false)

    const [payloadLaporan, setPayloadLaporan] = useState(initialPayload)
    const [submitLaporan, setSubmitLaporan] = useState(false)
    const [loadingLaporan, setLoadingLaporan] = useState(false)
    const [errorsLaporan, setErrorsLaporan] = useState({})
    const [selectedFilesLaporan, setSelectedFilesLaporan] = useState({
        file: null,
    })

    const [diikuti, setDiikuti] = useState(null)
    const [modulAktif, setModulAktif] = useState(0)
    const [tab, setTab] = useState("Rasional")
    const [tab2, setTab2] = useState("Progress")

    const [urlPdf, setUrlPdf] = useState(null)
    const [idPdf, setIdPdf] = useState(null)
    const [totalPagePdf, setTotalPagePdf] = useState(null)
    const [canDownloadPdf, setCanDownloadPdf] = useState(null)

    const [urlGambar, setUrlGambar] = useState(null)
    const [idGambar, setIdGambar] = useState(null)
    const [canDownloadGambar, setCanDownloadGambar] = useState(null)

    const [urlVideo, setUrlVideo] = useState(null)
    const [idVideo, setIdVideo] = useState(null)
    const [minDurasiVideo, setMinDurasiVideo] = useState(null)
    const [nilaiMinDurasiVideo, setNilaiMinDurasiVideo] = useState(null)
    const [canDownloadVideo, setCanDownloadVideo] = useState(null)
    const [currentTimeVideo, setCurrentTimeVideo] = useState(null)
    const [totalTime, setTotalTime] = useState(null)

    const [idLaporan, setIdLaporan] = useState(null)

    const [chats, setChats] = useState([])
    const [loadingSubmitChat, setLoadingSubmitChat] = useState(false)
    const [newChats, setNewChats] = useState([])
    const [payloadChatText, setPayloadChatText] = useState("")
    // const [firstLoad, setFirstLoad] = useState(true)

    const chatsEndRef = useRef(null)

    useEffect(() => {
        const titlePage = "Detail Kursus"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        // if (state) {
        //     setData(state)
        //     setDiikuti(false)
        // } else {
        //     getData()
        // }
        // setData(state)
        getData()
        getChat()

        actionResetDeleteKursus()

        return () => actionResetDeleteKursus()
    }, [])

    useEffect(() => {
        let intervalId = setInterval(() => getLatestChat(), 5000)

        return () => clearInterval(intervalId)
    }, [chats])

    const getChat = async () => {
        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            }

            let data = {
                pertemuan_id: pertemuanId
            }

            const res = await request(process.env.REACT_APP_SERVICE_CHAT + '/load', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify(data)
            })

            if (res?.success === true) {
                let result = res?.result
                setChats(result)
                // setFirstLoad(false)
                setTimeout(() => scrollToBottom(), 200)
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const getLatestChat = async () => {
        let lastChat = chats.at(-1)
        if (lastChat) {
            try {
                const headerPost = {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                }

                let data = {
                    pertemuan_id: pertemuanId,
                    last_id: lastChat?.id
                }

                const res = await request(process.env.REACT_APP_SERVICE_CHAT + '/load-after', {
                    method: 'POST',
                    headers: headerPost,
                    body: JSON.stringify(data)
                })

                if (res?.success === true) {
                    let result = res?.result
                    if (result?.length > 0) {
                        let toPush = chats.concat(result)
                        setChats(toPush)
                        setNewChats([])
                    }
                }
            } catch (error) {
                alert({ title: "Gagal", html: "Gagal meminta data ke server" })
            }
        }
    }

    const getOldestChat = async () => {
        let firstChat = chats.at(0)
        if (firstChat) {
            try {
                const headerPost = {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                }

                let data = {
                    pertemuan_id: pertemuanId,
                    last_id: firstChat?.id
                }

                const res = await request(process.env.REACT_APP_SERVICE_CHAT + '/load-before', {
                    method: 'POST',
                    headers: headerPost,
                    body: JSON.stringify(data)
                })

                if (res?.success === true) {
                    let result = res?.result
                    if (result?.length > 0) {
                        let first = chats[0]
                        let toPush = result.concat(chats)
                        setChats(toPush)
                        setNewChats([])
                        scrollToStart(first)
                    }
                }
            } catch (error) {
                alert({ title: "Gagal", html: "Gagal meminta data ke server" })
            }
        }
    }

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/' + pertemuanId, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                let result = res?.result
                let progress = res?.result?.progress

                delete result['progress']

                setData(result)
                setProgress(progress)
                setDiikuti(false)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    const viewHeader = () => {
        return <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            {
                                data === null ?
                                    null
                                    : <React.Fragment>
                                        <h4>
                                            {data?.nama}
                                            {" "}
                                            {
                                                progress !== null &&
                                                    progress?.jobsheet?.jumlahSelesai === progress?.jobsheet?.jumlahSemua &&
                                                    progress?.gambar?.jumlahSelesai === progress?.gambar?.jumlahSemua &&
                                                    progress?.video?.jumlahSelesai === progress?.video?.jumlahSemua &&
                                                    progress?.quiz?.jumlahSelesai === progress?.quiz?.jumlahSemua &&
                                                    progress?.laporan?.jumlahSelesai === progress?.laporan?.jumlahSemua
                                                    ?
                                                    <i className="bx bx-check text-success"></i>
                                                    : null
                                            }
                                        </h4>

                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: data?.keterangan }}></p>

                                        <p className="card-text">
                                            <small className="text-muted">Terakhir diperbarui oleh {data?.updated_by?.name || "-"}</small>
                                            {/* <br />
                                            <small className="text-muted">{data?.updated_at}</small> */}
                                        </p>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const getPct = (val1, val2) => {
        if (val1 && val2) {
            return parseInt((val1 / val2) * 100);
        }

        return 0
    }

    const openPdfModal = (e, id, fileName, canDownload) => {
        let url = process.env.REACT_APP_PENGAJAR_SERVICE + '/uploads/jobsheet/' + fileName
        let openPdfModal = document.getElementById('openPdfModal')

        if (openPdfModal) {
            setIdPdf(id)
            setUrlPdf(url)
            setCanDownloadPdf(canDownload)
            openPdfModal.click()
        }

        e.preventDefault()
    }

    const updateProgressJobsheet = (page) => {
        let _data = data
        let _jobsheet = _data?.jobsheet?.filter((val) => val?.id === idPdf)

        if (_jobsheet?.length > 0) {
            let _singleJobsheet = _jobsheet[0]

            if (_singleJobsheet?.status_selesai !== "Selesai") {
                _jobsheet?.map((val) => {
                    val.halaman_selesai = page
                    val.status_selesai = "Selesai"
                })

                let _progress = {
                    ...progress
                }

                _progress['jobsheet']['jumlahSelesai'] = _data?.jobsheet?.filter((val) => val.status_selesai === "Selesai")?.length

                setCanDownloadPdf(true)
                setData(_data)
                setProgress(_progress)
                updateDataJobsheet(_jobsheet[0])
            }
        }
    }

    const updateDataJobsheet = (jobsheet) => {
        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            }

            let data = {
                id: jobsheet?.id,
                halaman_selesai: jobsheet?.halaman_selesai,
                status_selesai: jobsheet?.status_selesai
            }

            request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/progress-jobsheet', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify({ "data": data })
            })
        } catch (error) { }
    }

    useEffect(() => {

        if (totalPagePdf === 1) {
            updateProgressJobsheet(1)
        } else {
            let indexJobsheet = data?.jobsheet?.findIndex((val) => val?.id === idPdf)
            if (indexJobsheet >= 0) {
                let nilaiMinimal = data?.jobsheet[indexJobsheet]?.nilai_minimal_baca_halaman

                if (nilaiMinimal <= 1) {
                    updateProgressJobsheet(1)
                }
            }
        }
    }, [totalPagePdf])

    const onClickNextPdf = (page) => {
        let indexJobsheet = data?.jobsheet?.findIndex((val) => val?.id === idPdf)

        if (indexJobsheet >= 0) {
            let status_selesai = data?.jobsheet[indexJobsheet]?.status_selesai

            if (status_selesai === "Belum Selesai") {
                let minimal = data?.jobsheet[indexJobsheet]?.minimal_baca_halaman
                let nilaiMinimal = data?.jobsheet[indexJobsheet]?.nilai_minimal_baca_halaman

                if (minimal === "Semua") {
                    if (totalPagePdf === page) {
                        updateProgressJobsheet(page)
                    }
                } else {
                    if (nilaiMinimal === page) {
                        updateProgressJobsheet(page)
                    }
                }
            }
        }
    }

    const downloadPdf = () => {
        const a = document.createElement('a')
        a.href = urlPdf
        a.target = "_blank"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const viewJobsheet = () => {
        return <React.Fragment>
            {
                data?.jobsheet?.length > 0 ?
                    <React.Fragment>
                        <a href="#" id="openPdfModal" type="button" data-bs-toggle="modal" data-bs-target="#modalPdf" className="d-none">Buka PDF</a>
                        <Modal id={"modalPdf"} title={"Lihat Jobsheet"} button={{ visible: true, label: "Download", class: "mx-auto", disabled: canDownloadPdf !== true, onClick: canDownloadPdf === true ? downloadPdf : null }} size={"modal-fullscreen"} button2={{ visible: false }}>
                            <PDFViewer url={urlPdf} setTotalPage={setTotalPagePdf} onClickNext={onClickNextPdf} />
                        </Modal>
                        {
                            data?.jobsheet?.map((val, key) => {
                                return <div key={key} className="card mt-1 mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {val?.nama} {val?.status_selesai === "Selesai" ? <i className="bx bx-check text-success"></i> : null}
                                        </h5>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan }}></p>
                                        <p className="card-text">
                                            <small className="text-muted">Terakhir diperbarui oleh {val?.updated_by?.name || "-"}</small>
                                            {/* <br />
                                            <small className="text-muted">{val?.updated_at || "-"}</small> */}
                                        </p>

                                        <button type="button" onClick={(e) => openPdfModal(e, val?.id, val?.file_name_real, val?.status_selesai === "Selesai")} className="btn btn-primary btn-sm text-nowrap">
                                            Lihat
                                        </button>
                                    </div>
                                </div>
                            })
                        }
                    </React.Fragment>

                    : <div className="card mt-1 mb-3">
                        <div className="card-body">
                            <p className="card-text">Jobsheet tidak tersedia</p>
                        </div>
                    </div>
            }
        </React.Fragment>
    }

    const openGambarModal = (e, id, fileName, canDownload) => {
        let url = process.env.REACT_APP_PENGAJAR_SERVICE + '/uploads/gambar/' + fileName
        let openGambarModal = document.getElementById('openGambarModal')

        if (openGambarModal) {
            setIdGambar(id)
            setUrlGambar(url)
            setCanDownloadGambar(canDownload)
            openGambarModal.click()
        }

        e.preventDefault()
    }

    const downloadGambar = () => {
        const a = document.createElement('a')
        a.href = urlGambar
        a.target = "_blank"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const updateProgressGambar = () => {
        let _data = data
        let _gambar = _data?.gambar?.filter((val) => val?.id === idGambar)

        if (_gambar?.length > 0) {
            _gambar?.map((val) => {
                val.status_selesai = "Selesai"
            })

            let _progress = {
                ...progress
            }

            _progress['gambar']['jumlahSelesai'] = _data?.gambar?.filter((val) => val.status_selesai === "Selesai")?.length

            setCanDownloadGambar(true)
            setData(_data)
            setProgress(_progress)
            updateDataGambar(_gambar[0])
        }
    }

    const updateDataGambar = (gambar) => {
        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            }

            let data = {
                id: gambar?.id,
                status_selesai: gambar?.status_selesai
            }

            request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/progress-gambar', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify({ "data": data })
            })
        } catch (error) { }
    }

    const viewGambar = () => {
        return <React.Fragment>
            {
                data?.gambar?.length > 0 ?
                    <React.Fragment>
                        <a href="#" id="openGambarModal" type="button" data-bs-toggle="modal" data-bs-target="#modalGambar" className="d-none">Buka Gambar</a>
                        <Modal id={"modalGambar"} title={"Lihat Gambar"} button={{ visible: true, label: "Download", class: "mx-auto", disabled: canDownloadGambar !== true, onClick: canDownloadGambar === true ? downloadGambar : null }} size={"modal-fullscreen"} button2={{ visible: false }}>
                            <div style={{ width: "100%" }} className="text-center">
                                <img
                                    onLoad={updateProgressGambar}
                                    src={urlGambar}
                                    alt={urlGambar}
                                    className="img-fluid"
                                // style={{ maxHeight: "100%" }}
                                />
                            </div>
                        </Modal>
                        {
                            data?.gambar?.map((val, key) => {
                                return <div key={key} className="card mt-1 mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {val?.nama} {val?.status_selesai === "Selesai" ? <i className="bx bx-check text-success"></i> : null}
                                        </h5>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan }}></p>
                                        <p className="card-text">
                                            <small className="text-muted">Terakhir diperbarui oleh {val?.updated_by?.name || "-"}</small>
                                            {/* <br />
                                            <small className="text-muted">{val?.updated_at || "-"}</small> */}
                                        </p>

                                        <button type="button" onClick={(e) => openGambarModal(e, val?.id, val?.file_name_real, val?.status_selesai === "Selesai")} className="btn btn-primary btn-sm text-nowrap">
                                            Lihat
                                        </button>
                                    </div>
                                </div>
                            })
                        }
                    </React.Fragment>
                    : <div className="card mt-1 mb-3">
                        <div className="card-body">
                            <p className="card-text">Gambar tidak tersedia</p>
                        </div>
                    </div>
            }
        </React.Fragment>
    }

    const openVideoModal = (e, id, fileName, canDownload, minDurasi, nilaiMinDurasi) => {
        let url = process.env.REACT_APP_PENGAJAR_SERVICE + '/uploads/video/' + fileName
        let openVideoModal = document.getElementById('openVideoModal')

        if (openVideoModal) {
            setIdVideo(id)
            setUrlVideo(url)
            setCanDownloadVideo(canDownload)
            setMinDurasiVideo(minDurasi)
            setNilaiMinDurasiVideo(nilaiMinDurasi)
            openVideoModal.click()
        }

        e.preventDefault()
    }

    const downloadVideo = () => {
        const a = document.createElement('a')
        a.href = urlVideo
        a.target = "_blank"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const updateDataVideo = (video) => {
        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            }

            let data = {
                id: video?.id,
                durasi_selesai: video?.durasi_selesai,
                status_selesai: video?.status_selesai
            }

            request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/progress-video', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify({ "data": data })
            })
        } catch (error) { }
    }

    const updateProgressVideo = (sec) => {
        let _data = data
        let _video = _data?.video?.filter((val) => val?.id === idVideo)

        if (_video?.length > 0) {
            _video?.map((val) => {
                val.durasi_selesai = sec
                val.status_selesai = "Selesai"
            })

            let _progress = {
                ...progress
            }

            _progress['video']['jumlahSelesai'] = _data?.video?.filter((val) => val.status_selesai === "Selesai")?.length

            setCanDownloadVideo(true)
            setData(_data)
            setProgress(_progress)
            updateDataVideo(_video[0])
        }
    }

    useEffect(() => {

        if (minDurasiVideo === "Semua") {
            if (currentTimeVideo === -1) {
                updateProgressVideo(currentTimeVideo)
            }
        } else {
            if (currentTimeVideo === nilaiMinDurasiVideo) {
                updateProgressVideo(currentTimeVideo)
            }
        }

    }, [currentTimeVideo])

    const viewVideo = () => {
        return <React.Fragment>
            {
                data?.video?.length > 0 ?
                    <React.Fragment>
                        <a href="#" id="openVideoModal" type="button" data-bs-toggle="modal" data-bs-target="#modalVideo" className="d-none">Buka Video</a>
                        <Modal id={"modalVideo"} title={"Lihat Video"} button={{ visible: true, label: "Download", class: "mx-auto", disabled: canDownloadVideo !== true, onClick: canDownloadVideo === true ? downloadVideo : null }} size={"modal-fullscreen"} button2={{ visible: false }}>
                            <VideoPlayer url={urlVideo} track={true} setCurrentTime={setCurrentTimeVideo} setTotalTime={setTotalTime} />
                        </Modal>

                        {data?.video?.map((val, key) => {
                            return <div key={key} className="card mt-1 mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        {val?.nama} {val?.status_selesai === "Selesai" ? <i className="bx bx-check text-success"></i> : null}
                                    </h5>
                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan }}></p>
                                    <p className="card-text">
                                        <small className="text-muted">Terakhir diperbarui oleh {val?.updated_by?.name || "-"}</small>
                                        {/* <br />
                                        <small className="text-muted">{val?.updated_at || "-"}</small> */}
                                    </p>

                                    <button type="button" onClick={(e) => openVideoModal(e, val?.id, val?.file_name_real, val?.status_selesai === "Selesai", val?.minimal_durasi_lihat, val?.nilai_minimal_durasi_lihat)} className="btn btn-primary btn-sm text-nowrap">
                                        Lihat
                                    </button>
                                </div>
                            </div>
                        })
                        }
                    </React.Fragment>
                    : <div className="card mt-1 mb-3">
                        <div className="card-body">
                            <p className="card-text">Video tidak tersedia</p>
                        </div>
                    </div>
            }
        </React.Fragment>
    }

    const viewQuiz = () => {
        return <React.Fragment>
            <div className="card mt-1 mb-3">
                <div className="card-body">
                    {
                        data?.quiz > 0 ? <React.Fragment>
                            <ul className="list-unstyled mb-2">
                                <li className="d-flex align-items-center mb-2"><i className="bx bx-list-ul fw-semibold"></i><span className="mx-2 text-muted">Jumlah Pertanyaan:</span> <span className="text-muted">{data?.quiz}</span></li>
                                <li className="d-flex align-items-center mb-2"><i className="bx bx-time-five fw-semibold"></i><span className="mx-2 text-muted">Durasi Quiz:</span> <span className="text-muted">{data?.durasi_quiz} menit</span></li>
                                {
                                    data?.selesaiQuiz === true ?
                                        <li className="d-flex align-items-center mb-2"><i className="bx bx-book-reader fw-semibold"></i><span className="mx-2 text-muted">Nilai Quiz:</span> <span className="text-muted">{data?.nilaiQuiz}</span></li>
                                        : null
                                }
                            </ul>
                            {
                                data?.selesaiQuiz === false ?
                                    <Link to={{
                                        pathname: process.env.REACT_APP_SUBDIR + "/kursus/detail/" + id + "/pertemuan/" + pertemuanId + "/quiz"
                                    }} className="btn btn-primary btn-sm mt-2">Kerjakan</Link>
                                    : null
                            }
                        </React.Fragment>
                            : <p className="card-text">Quiz tidak tersedia</p>
                    }
                </div>
            </div>
        </React.Fragment>
    }

    const viewQuizAwal = () => {
        return <React.Fragment>
            <div className="card mt-1 mb-3">
                <div className="card-body">
                    {
                        data?.quizAwal > 0 ? <React.Fragment>
                            <ul className="list-unstyled mb-2">
                                <li className="d-flex align-items-center mb-2"><i className="bx bx-list-ul fw-semibold"></i><span className="mx-2 text-muted">Jumlah Pertanyaan:</span> <span className="text-muted">{data?.quizAwal}</span></li>
                                <li className="d-flex align-items-center mb-2"><i className="bx bx-time-five fw-semibold"></i><span className="mx-2 text-muted">Durasi Quiz:</span> <span className="text-muted">{data?.durasi_quiz} menit</span></li>
                                {
                                    data?.selesaiQuizAwal === true ?
                                        <li className="d-flex align-items-center mb-2"><i className="bx bx-book-reader fw-semibold"></i><span className="mx-2 text-muted">Nilai Quiz:</span> <span className="text-muted">{data?.nilaiQuizAwal}</span></li>
                                        : null
                                }
                            </ul>
                            {
                                data?.selesaiQuizAwal === false ?
                                    <Link to={{
                                        pathname: process.env.REACT_APP_SUBDIR + "/kursus/detail/" + id + "/pertemuan/" + pertemuanId + "/quiz-awal"
                                    }} className="btn btn-primary btn-sm mt-2">Kerjakan</Link>
                                    : null
                            }
                        </React.Fragment>
                            : <p className="card-text">Quiz awal tidak tersedia</p>
                    }
                </div>
            </div>
        </React.Fragment>
    }

    const openLaporanModal = (e, id, fileName, canDownload, minDurasi, nilaiMinDurasi) => {
        let openLaporanModal = document.getElementById('openLaporanModal')

        if (openLaporanModal) {
            setIdLaporan(id)
            openLaporanModal.click()
        }

        e.preventDefault()
    }


    const onDropLaporan = async (name, files) => {
        if (files?.length > 0) {
            let reader = new FileReader()
            reader.readAsDataURL(files[0])

            reader.onload = () => {
                let _selectedFiles = {
                    ...selectedFilesLaporan
                }
                _selectedFiles[name] = files
                setSelectedFilesLaporan(_selectedFiles)

                let _payload = {
                    ...payloadLaporan
                }
                let fileName = files[0]?.name
                // let base64 = btoa(reader.result)
                let base64 = reader.result
                let extension = fileName?.substring(fileName.lastIndexOf('.') + 1)
                let size = files[0]?.size
                _payload[name] = {
                    name: fileName,
                    base64,
                    size,
                    extension
                }
                setPayloadLaporan(_payload)
            }
            reader.onerror = () => {
                let jsonErrors = {
                    ...errorsLaporan
                }
                jsonErrors[name] = "Terjadi kesalahan"
                setErrorsLaporan(jsonErrors)
            }
        }

    }

    const updateDataLaporan = async ({ payload, accessToken }) => {
        setSubmitLaporan(true)
        setLoadingLaporan(true)

        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            }

            let data = {
                ...payload,
                id: idLaporan,
            }

            const res = await request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/progress-laporan', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify({ "data": data })
            })

            if (res?.success === true) {
                updateProgressLaporan(res?.result?.file)
                await alert({ title: "Berhasil", html: "Berhasil kirim data.", icon: "success" })
                closeModal()
            } else {
                setSubmitLaporan(false)
                await alert({ title: "Gagal", html: "Gagal kirim data, terjadi kesalahan di sisi server." })
                closeModal()
            }
            setLoadingLaporan(false)
        } catch (error) {
            setLoadingLaporan(false)
            setSubmitLaporan(false)
            await alert({ title: "Gagal", html: "Gagal kirim data, terjadi kesalahan di sisi server." })
            closeModal()
        }
    }

    const updateProgressLaporan = (fileName) => {
        let _data = data
        let _laporan = _data?.laporan?.filter((val) => val?.id === idLaporan)

        if (_laporan?.length > 0) {
            _laporan?.map((val) => {
                val.status_selesai = "Selesai"
                val.file = fileName
            })

            let _progress = {
                ...progress
            }

            _progress['laporan']['jumlahSelesai'] = _data?.laporan?.filter((val) => val.status_selesai === "Selesai")?.length

            setData(_data)
            setProgress(_progress)
        }
    }

    const onSubmit = (e) => {
        if (validateLaporan() === true) {
            updateDataLaporan({
                payload: payloadLaporan,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validateLaporan = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payloadLaporan?.file?.name || payloadLaporan?.file?.name === "") {
            hasError = true
            jsonErrors["file"] = "File harus diisi"
        }

        setErrorsLaporan(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const viewLaporan = () => {
        return <React.Fragment>
            {
                data?.laporan?.length > 0 ?
                    <React.Fragment>
                        <a href="#" id="openLaporanModal" type="button" data-bs-toggle="modal" data-bs-target="#modalLaporan" className="d-none">Buka Laporan</a>
                        <Modal id={"modalLaporan"} title={"Lihat Laporan"} button={{ visible: false }} size={"modal-fullscreen"} button2={{ visible: false }}>
                            <form onSubmit={onSubmit} >
                                <FileInput
                                    name={"file"}
                                    label={"File"}
                                    error={errorsLaporan?.file}
                                    accept={{
                                        'application/pdf': ['.pdf']
                                    }}
                                    placeholder={"Pilih file"}
                                    selectedFiles={selectedFilesLaporan?.file}
                                    onDrop={(e) => onDropLaporan('file', e)}
                                    tabIndex={1}
                                />

                                <button
                                    tabIndex={2}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={loadingLaporan === true}>
                                    Kirim
                                    {loadingLaporan === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </form>
                        </Modal>

                        {
                            data?.laporan?.map((val, key) => {
                                return <div key={key} className="card mt-1 mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {val?.nama} {val?.status_selesai === "Selesai" ? <i className="bx bx-check text-success"></i> : null}
                                        </h5>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: val?.keterangan }}></p>
                                        <p className="card-text">
                                            <small className="text-muted">Terakhir diperbarui oleh {val?.updated_by?.name || "-"}</small>
                                            {/* <br />
                                            <small className="text-muted">{val?.updated_at || "-"}</small> */}
                                        </p>

                                        {
                                            val?.status_selesai === "Selesai" ?
                                                <a href={process.env.REACT_APP_SERVICE + '/uploads/laporan/' + val?.file} className="btn btn-primary btn-sm text-nowrap" target="_blank">
                                                    Download
                                                </a>
                                                :
                                                <button type="button" onClick={(e) => openLaporanModal(e, val?.id)} className="btn btn-primary btn-sm text-nowrap">
                                                    Kirim
                                                </button>
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </React.Fragment>
                    : <div className="card mt-1 mb-3">
                        <div className="card-body">
                            <p className="card-text">Tugas tidak tersedia</p>
                        </div>
                    </div>
            }
        </React.Fragment>
    }

    const scrollToBottom = () => {
        chatsEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const scrollToStart = (data) => {
        let el = document.getElementById("el-" + data?.id)
        if (el) {
            el.scrollIntoView({ behavior: "instant" })
        }
    }

    const handleScrollChat = (e) => {
        let pos = e?.target?.scrollTop
        if (pos <= 0) {
            if (chats?.length > 0) {
                getOldestChat()
            }
        }
    }

    const viewForum = () => {
        return <React.Fragment>
            <div className="card mt-1 mb-3">
                <div className="card-body px-0">
                    <div className="app-chat overflow-hidden" onScroll={handleScrollChat}>
                        <div className="app-chat-history">
                            <div className="chat-history-body">
                                <ul className="list-unstyled chat-history mb-0">
                                    {
                                        chats?.length > 0 ?
                                            chats?.map((val, key) => {
                                                return user?.id !== val?.user?.id ?
                                                    <li className="chat-message mb-1" key={"chats" + key} id={"el-" + val?.id}>
                                                        <div className="d-flex overflow-hidden">
                                                            <div className="chat-message-wrapper flex-grow-1">
                                                                {
                                                                    showChatUser(key) ?
                                                                        <div className="text-muted mt-1">
                                                                            <small>{val?.user?.name}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div className="chat-message-text">
                                                                    <p className="mb-0">{val?.text}</p>
                                                                </div>
                                                                {
                                                                    showChatTime(key) ?
                                                                        <div className="text-muted mt-1 mb-2">
                                                                            <small>{val?.created_at}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                    :
                                                    < li className="chat-message chat-message-right mb-1" key={"chats" + key} id={"el-" + val?.id}>
                                                        <div className="d-flex overflow-hidden">
                                                            <div className="chat-message-wrapper flex-grow-1">
                                                                {
                                                                    showChatUser(key) ?
                                                                        <div className="text-end text-muted mt-1">
                                                                            <small>{val?.user?.name}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div className="chat-message-text">
                                                                    <p className="mb-0">{val?.text}</p>
                                                                </div>
                                                                {
                                                                    showChatTime(key) ?
                                                                        <div className="text-end text-muted mt-1 mb-2">
                                                                            {/* <i className="bx bx-check-double text-muted"></i> */}
                                                                            <small>{val?.created_at}</small>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                            })
                                            : null
                                    }

                                    {
                                        newChats?.length > 0 ?
                                            newChats?.map((val, key) => {
                                                return < li className="chat-message chat-message-right mb-1" key={"newChats" + key}>
                                                    <div className="d-flex overflow-hidden">
                                                        <div className="chat-message-wrapper flex-grow-1">
                                                            {
                                                                showNewChatUser(key) ?
                                                                    <div className="text-end text-muted mt-1">
                                                                        <small>{val?.user?.name}</small>
                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className="chat-message-text">
                                                                <p className="mb-0">{val?.text}</p>
                                                            </div>
                                                            {
                                                                showNewChatTime(key) ?
                                                                    <div className="text-end text-muted mt-1">
                                                                        {/* <i className="bx bx-check-double text-muted"></i> */}
                                                                        <small>{val?.created_at}</small>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            })
                                            : null
                                    }

                                    {
                                        newChats?.length > 0 || chats?.length > 0 ? null
                                            : <li className="text-muted text-center">Belum ada pesan</li>
                                    }

                                    <li>
                                        <div ref={chatsEndRef} />
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="bg-abu">
                    <form onSubmit={onSubmitChat} className="form-send-message d-flex justify-content-between align-items-center ">
                        <input className="form-control message-input border-0 me-3 shadow-none" value={payloadChatText} onChange={onChangeChatText} placeholder="Ketik pesan anda..." />
                        <div className="message-actions d-flex align-items-center">
                            <button type="submit" className="btn btn-primary d-flex send-msg-btn" disabled={loadingSubmitChat === true}>
                                <span className="align-middle">
                                    {loadingSubmitChat === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : "Kirim"}
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment >
    }

    const showChatUser = (key) => {
        let arr = chats
        let val1 = arr.at(key)
        let val2 = arr.at(key - 1)

        if (key === 0) {
            return true
        }

        if (!val2) {
            return true
        }

        if (val1?.created_at !== val2?.created_at) {
            return true
        }

        if (val1?.user_id !== val2?.user_id) {
            return true
        }

        return false
    }

    const showNewChatUser = (key) => {
        key = (key + chats?.length)
        let arr = [...chats, ...newChats]
        // let arr = newChats
        let val1 = arr.at(key)
        let val2 = arr.at(key - 1)

        if (key === 0) {
            return true
        }

        if (!val2) {
            return true
        }

        if (val1?.created_at !== val2?.created_at) {
            return true
        }

        return false
    }

    const showChatTime = (key) => {
        let arr = chats
        let val1 = arr.at(key)
        let val2 = arr.at(key + 1)

        if (!val2) {
            return true
        }

        if (val1?.created_at !== val2?.created_at) {
            return true
        }

        if (val1?.user_id !== val2?.user_id) {
            return true
        }

        return false
    }

    const showNewChatTime = (key) => {
        key = parseInt(key) + parseInt(chats?.length)
        let arr = [...chats, ...newChats]
        // let arr = newChats
        let val1 = arr.at(key)
        let val2 = arr.at(key + 1)

        if (key === (arr?.length - 1)) {
            return true
        }

        if (!val2) {
            return true
        }

        if (val1?.created_at !== val2?.created_at) {
            return true
        }

        return false
    }

    const onChangeChatText = (e) => {
        setPayloadChatText(e?.target?.value)
    }

    const onSubmitChat = async (e) => {
        e.preventDefault()

        if (payloadChatText) {
            setLoadingSubmitChat(true)

            try {
                const headerPost = {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                }

                let data = {
                    pertemuan_id: pertemuanId,
                    text: payloadChatText
                }

                const res = await request(process.env.REACT_APP_SERVICE_CHAT + '/send', {
                    method: 'POST',
                    headers: headerPost,
                    body: JSON.stringify(data)
                })

                if (res?.success === true) {
                    setPayloadChatText("")
                    let _newChats = [...newChats]
                    _newChats.push({
                        user: {
                            id: user?.id,
                            name: user?.name
                        },
                        user_id: user?.id,
                        pertemuan_id: pertemuanId,
                        text: payloadChatText,
                        created_at: dateTime((new Date).getTime(), "DD-MM-YY HH:mm")
                    })
                    setNewChats(_newChats)
                    scrollToBottom()
                } else {
                    await alert({ title: "Gagal", html: "Gagal kirim data, terjadi kesalahan di sisi server." })
                }
                setLoadingSubmitChat(false)
            } catch (error) {
                setLoadingSubmitChat(false)
                await alert({ title: "Gagal", html: "Gagal kirim data, terjadi kesalahan di sisi server." })
            }
        }
    }

    const viewProgress = () => {
        return <React.Fragment>
            <div className="card mt-1 mb-3">
                <div className="card-body">
                    {
                        progress?.jobsheet?.jumlahSemua > 0 ?
                            <div className="mb-2">
                                <small className="text-muted">Jobsheet </small><small>{progress?.jobsheet?.jumlahSelesai || 0}/{progress?.jobsheet?.jumlahSemua || 0}</small>
                                <div className="progress mt-1">
                                    <div className={"progress-bar " + (getPct(progress?.jobsheet?.jumlahSelesai, progress?.jobsheet?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(progress?.jobsheet?.jumlahSelesai, progress?.jobsheet?.jumlahSemua) + "%" }} aria-valuenow={getPct(progress?.jobsheet?.jumlahSelesai, progress?.jobsheet?.jumlahSemua)} aria-valuemin={getPct(progress?.jobsheet?.jumlahSelesai, progress?.jobsheet?.jumlahSemua)} aria-valuemax="100">{getPct(progress?.jobsheet?.jumlahSelesai, progress?.jobsheet?.jumlahSemua)}%</div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        progress?.gambar?.jumlahSemua > 0 ?
                            <div className="mb-2">
                                <small className="text-muted">Gambar </small><small>{progress?.gambar?.jumlahSelesai || 0}/{progress?.gambar?.jumlahSemua || 0}</small>
                                <div className="progress mt-1">
                                    <div className={"progress-bar " + (getPct(progress?.gambar?.jumlahSelesai, progress?.gambar?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(progress?.gambar?.jumlahSelesai, progress?.gambar?.jumlahSemua) + "%" }} aria-valuenow={getPct(progress?.gambar?.jumlahSelesai, progress?.gambar?.jumlahSemua)} aria-valuemin={getPct(progress?.gambar?.jumlahSelesai, progress?.gambar?.jumlahSemua)} aria-valuemax="100">{getPct(progress?.gambar?.jumlahSelesai, progress?.gambar?.jumlahSemua)}%</div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        progress?.video?.jumlahSemua > 0 ?
                            <div className="mb-2">
                                <small className="text-muted">Video </small><small>{progress?.video?.jumlahSelesai || 0}/{progress?.video?.jumlahSemua || 0}</small>
                                <div className="progress mt-1">
                                    <div className={"progress-bar " + (getPct(progress?.video?.jumlahSelesai, progress?.video?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(progress?.video?.jumlahSelesai, progress?.video?.jumlahSemua) + "%" }} aria-valuenow={getPct(progress?.video?.jumlahSelesai, progress?.video?.jumlahSemua)} aria-valuemin={getPct(progress?.video?.jumlahSelesai, progress?.video?.jumlahSemua)} aria-valuemax="100">{getPct(progress?.video?.jumlahSelesai, progress?.video?.jumlahSemua)}%</div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        progress?.quiz?.jumlahSemua > 0 ?
                            <div className="mb-2">
                                <small className="text-muted">Quiz </small><small>{progress?.quiz?.jumlahSelesai || 0}/{progress?.quiz?.jumlahSemua || 0}</small>
                                <div className="progress mt-1">
                                    <div className={"progress-bar " + (getPct(progress?.quiz?.jumlahSelesai, progress?.quiz?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(progress?.quiz?.jumlahSelesai, progress?.quiz?.jumlahSemua) + "%" }} aria-valuenow={getPct(progress?.quiz?.jumlahSelesai, progress?.quiz?.jumlahSemua)} aria-valuemin={getPct(progress?.quiz?.jumlahSelesai, progress?.quiz?.jumlahSemua)} aria-valuemax="100">{getPct(progress?.quiz?.jumlahSelesai, progress?.quiz?.jumlahSemua)}%</div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        progress?.laporan?.jumlahSemua > 0 ?
                            <div className="mb-2">
                                <small className="text-muted">Tugas </small><small>{progress?.laporan?.jumlahSelesai || 0}/{progress?.laporan?.jumlahSemua || 0}</small>
                                <div className="progress mt-1">
                                    <div className={"progress-bar " + (getPct(progress?.laporan?.jumlahSelesai, progress?.laporan?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(progress?.laporan?.jumlahSelesai, progress?.laporan?.jumlahSemua) + "%" }} aria-valuenow={getPct(progress?.laporan?.jumlahSelesai, progress?.laporan?.jumlahSemua)} aria-valuemin={getPct(progress?.laporan?.jumlahSelesai, progress?.laporan?.jumlahSemua)} aria-valuemax="100">{getPct(progress?.laporan?.jumlahSelesai, progress?.laporan?.jumlahSemua)}%</div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        progress?.quizAwal?.jumlahSemua > 0 ?
                            <div className="mb-2">
                                <small className="text-muted">Quiz Awal </small><small>{progress?.quizAwal?.jumlahSelesai || 0}/{progress?.quizAwal?.jumlahSemua || 0}</small>
                                <div className="progress mt-1">
                                    <div className={"progress-bar " + (getPct(progress?.quizAwal?.jumlahSelesai, progress?.quizAwal?.jumlahSemua) >= 100 ? "bg-success" : "")} role="progressbar" style={{ width: getPct(progress?.quizAwal?.jumlahSelesai, progress?.quizAwal?.jumlahSemua) + "%" }} aria-valuenow={getPct(progress?.quizAwal?.jumlahSelesai, progress?.quizAwal?.jumlahSemua)} aria-valuemin={getPct(progress?.quizAwal?.jumlahSelesai, progress?.quizAwal?.jumlahSemua)} aria-valuemax="100">{getPct(progress?.quizAwal?.jumlahSelesai, progress?.quizAwal?.jumlahSemua)}%</div>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        </React.Fragment>
    }

    const viewBodyLeft = () => {
        return <React.Fragment>
            <div className="nav-align-top mt-1 mb-3">
                <ul className="kursus nav nav-tabs pb-1" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab('Rasional')} type="button" className={"nav-link " + (tab === "Rasional" ? "active" : "")} tabIndex="-1">
                            Rasional
                        </button>
                    </li>

                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab('Quiz Awal')} type="button" className={"nav-link " + (tab === "Quiz Awal" ? "active" : "")} tabIndex="-1">
                            Quiz Awal
                            {
                                progress?.quizAwal?.jumlahSelesai > 0 && progress?.quizAwal?.jumlahSelesai === progress?.quizAwal?.jumlahSemua ?
                                    <i className="bx bx-check text-success"></i>
                                    : null
                            }
                        </button>
                    </li>

                    {
                        data?.namaKelompok?.toLowerCase() === "activist" &&
                        <>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Video')} type="button" className={"nav-link " + (tab === "Video" ? "active" : "")} tabIndex="-1">
                                    Video
                                    {
                                        progress?.video?.jumlahSelesai > 0 && progress?.video?.jumlahSelesai === progress?.video?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Gambar')} type="button" className={"nav-link " + (tab === "Gambar" ? "active" : "")} tabIndex="-1">
                                    Gambar
                                    {
                                        progress?.gambar?.jumlahSelesai > 0 && progress?.gambar?.jumlahSelesai === progress?.gambar?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Tugas')} type="button" className={"nav-link " + (tab === "Tugas" ? "active" : "")} tabIndex="-1">
                                    Tugas
                                    {
                                        progress?.laporan?.jumlahSelesai > 0 && progress?.laporan?.jumlahSelesai === progress?.laporan?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Jobsheet')} type="button" className={"nav-link " + (tab === "Jobsheet" ? "active" : "")} tabIndex="-1">
                                    Jobsheet
                                    {
                                        progress?.jobsheet?.jumlahSelesai > 0 && progress?.jobsheet?.jumlahSelesai === progress?.jobsheet?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Quiz')} type="button" className={"nav-link " + (tab === "Quiz" ? "active" : "")} tabIndex="-1">
                                    Quiz
                                    {
                                        progress?.quiz?.jumlahSelesai > 0 && progress?.quiz?.jumlahSelesai === progress?.quiz?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                        </>
                    }
                    {
                        data?.namaKelompok?.toLowerCase() === "reflector" &&
                        <>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Video')} type="button" className={"nav-link " + (tab === "Video" ? "active" : "")} tabIndex="-1">
                                    Video
                                    {
                                        progress?.video?.jumlahSelesai > 0 && progress?.video?.jumlahSelesai === progress?.video?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Gambar')} type="button" className={"nav-link " + (tab === "Gambar" ? "active" : "")} tabIndex="-1">
                                    Gambar
                                    {
                                        progress?.gambar?.jumlahSelesai > 0 && progress?.gambar?.jumlahSelesai === progress?.gambar?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Jobsheet')} type="button" className={"nav-link " + (tab === "Jobsheet" ? "active" : "")} tabIndex="-1">
                                    Jobsheet
                                    {
                                        progress?.jobsheet?.jumlahSelesai > 0 && progress?.jobsheet?.jumlahSelesai === progress?.jobsheet?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Quiz')} type="button" className={"nav-link " + (tab === "Quiz" ? "active" : "")} tabIndex="-1">
                                    Quiz
                                    {
                                        progress?.quiz?.jumlahSelesai > 0 && progress?.quiz?.jumlahSelesai === progress?.quiz?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Tugas')} type="button" className={"nav-link " + (tab === "Tugas" ? "active" : "")} tabIndex="-1">
                                    Tugas
                                    {
                                        progress?.laporan?.jumlahSelesai > 0 && progress?.laporan?.jumlahSelesai === progress?.laporan?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                        </>
                    }
                    {
                        data?.namaKelompok?.toLowerCase() === "pragmatist" &&
                        <>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Video')} type="button" className={"nav-link " + (tab === "Video" ? "active" : "")} tabIndex="-1">
                                    Video
                                    {
                                        progress?.video?.jumlahSelesai > 0 && progress?.video?.jumlahSelesai === progress?.video?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Gambar')} type="button" className={"nav-link " + (tab === "Gambar" ? "active" : "")} tabIndex="-1">
                                    Gambar
                                    {
                                        progress?.gambar?.jumlahSelesai > 0 && progress?.gambar?.jumlahSelesai === progress?.gambar?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Jobsheet')} type="button" className={"nav-link " + (tab === "Jobsheet" ? "active" : "")} tabIndex="-1">
                                    Jobsheet
                                    {
                                        progress?.jobsheet?.jumlahSelesai > 0 && progress?.jobsheet?.jumlahSelesai === progress?.jobsheet?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Tugas')} type="button" className={"nav-link " + (tab === "Tugas" ? "active" : "")} tabIndex="-1">
                                    Tugas
                                    {
                                        progress?.laporan?.jumlahSelesai > 0 && progress?.laporan?.jumlahSelesai === progress?.laporan?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Quiz')} type="button" className={"nav-link " + (tab === "Quiz" ? "active" : "")} tabIndex="-1">
                                    Quiz
                                    {
                                        progress?.quiz?.jumlahSelesai > 0 && progress?.quiz?.jumlahSelesai === progress?.quiz?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                        </>
                    }
                    {
                        data?.namaKelompok?.toLowerCase() === "theorist" &&
                        <>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Jobsheet')} type="button" className={"nav-link " + (tab === "Jobsheet" ? "active" : "")} tabIndex="-1">
                                    Jobsheet
                                    {
                                        progress?.jobsheet?.jumlahSelesai > 0 && progress?.jobsheet?.jumlahSelesai === progress?.jobsheet?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Tugas')} type="button" className={"nav-link " + (tab === "Tugas" ? "active" : "")} tabIndex="-1">
                                    Tugas
                                    {
                                        progress?.laporan?.jumlahSelesai > 0 && progress?.laporan?.jumlahSelesai === progress?.laporan?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Video')} type="button" className={"nav-link " + (tab === "Video" ? "active" : "")} tabIndex="-1">
                                    Video
                                    {
                                        progress?.video?.jumlahSelesai > 0 && progress?.video?.jumlahSelesai === progress?.video?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Gambar')} type="button" className={"nav-link " + (tab === "Gambar" ? "active" : "")} tabIndex="-1">
                                    Gambar
                                    {
                                        progress?.gambar?.jumlahSelesai > 0 && progress?.gambar?.jumlahSelesai === progress?.gambar?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setTab('Quiz')} type="button" className={"nav-link " + (tab === "Quiz" ? "active" : "")} tabIndex="-1">
                                    Quiz
                                    {
                                        progress?.quiz?.jumlahSelesai > 0 && progress?.quiz?.jumlahSelesai === progress?.quiz?.jumlahSemua ?
                                            <i className="bx bx-check text-success"></i>
                                            : null
                                    }
                                </button>
                            </li>
                        </>
                    }

                </ul>

                {
                    data?.id ?
                        <React.Fragment>
                            <div className={"mt-2 " + (tab === "Rasional" ? "" : "d-none")}>
                                <div className="card mt-1 mb-3">
                                    <div className="card-body">
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: data?.rasional }}></p>
                                    </div>
                                </div>
                            </div>
                            <div className={"mt-2 " + (tab === "Quiz Awal" ? "" : "d-none")}>
                                {viewQuizAwal()}
                            </div>
                            <div className={"mt-2 " + (tab === "Jobsheet" ? "" : "d-none")}>
                                {viewJobsheet()}
                            </div>
                            <div className={"mt-2 " + (tab === "Gambar" ? "" : "d-none")}>
                                {viewGambar()}
                            </div>
                            <div className={"mt-2 " + (tab === "Video" ? "" : "d-none")}>
                                {viewVideo()}
                            </div>
                            <div className={"mt-2 " + (tab === "Quiz" ? "" : "d-none")}>
                                {viewQuiz()}
                            </div>
                            <div className={"mt-2 " + (tab === "Tugas" ? "" : "d-none")}>
                                {viewLaporan()}
                            </div>
                        </React.Fragment>
                        : null
                }
            </div>
        </React.Fragment>
    }

    const viewBodyRight = () => {
        return <React.Fragment>
            <div className="nav-align-top mt-1 mb-3">
                <ul className="kursus nav nav-tabs pb-1" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab2('Progress')} type="button" className={"nav-link " + (tab2 === "Progress" ? "active" : "")} tabIndex="-1">
                            Progress
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab2('Forum')} type="button" className={"nav-link " + (tab2 === "Forum" ? "active" : "")} tabIndex="-1">
                            Forum
                        </button>
                    </li>
                </ul>

                {
                    data?.id ?
                        <React.Fragment>
                            <div className={"mt-2 " + (tab2 === "Progress" ? "" : "d-none")}>
                                {viewProgress()}
                            </div>
                            <div className={"mt-2 " + (tab2 === "Forum" ? "" : "d-none")}>
                                {viewForum()}
                            </div>
                        </React.Fragment>
                        : null
                }
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row mb-2">
                <div className="col-lg-4">
                    <Link to={process.env.REACT_APP_SUBDIR + "/kursus/detail/" + id} className="btn btn-white mb-2 btn-md-block">Kembali</Link>
                </div>
                <div className="col-lg-8 text-end"></div>
            </div>

            {viewHeader()}

            <div className="row mb-2">
                <div className="col-xl-8 col-lg-7 col-md-7">
                    {viewBodyLeft()}
                </div>

                <div className="col-xl-4 col-lg-5 col-md-5">
                    {viewBodyRight()}
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken, user }, kursus: { del } } = state

    return { accessToken, user, del }
}

export default withRouter(connect(mapStateToProps, { actionDeleteKursus, actionResetDeleteKursus })(KursusPertemuanDetail))