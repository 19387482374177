import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import IlsLogin from "../assets/img/illustrations/ils-login.png"
import SvgLogo from "../assets/svg/logo.svg"
import { pushUrl } from "../utils/url"
import { changeTitle } from "../utils/page"
import { APP_NAME } from "../constants"
import { request } from "../utils/request"
import NumberInput from "../components/Form/NumberInput"
import { alert } from "../utils/alert"

const ForgetPassword = () => {
    const [payload, setPayload] = useState({ nik: "" })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        changeTitle("Lupa Password")
    }, [])

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)
            actionSubmit(payload)
        }

        e.preventDefault()
    }

    const actionSubmit = async (payload) => {
        setLoading(true)

        try {
            const headerPost = {
                "Content-Type": "application/json",
            }
            const res = await request(process.env.REACT_APP_SERVICE_AUTH + '/forget-password', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify(payload)
            })

            if (res?.success === true) {
                setSuccess(true)
                alert({ title: "Berhasil", html: res?.message })
            } else {
                setSuccess(false)
                setErrors(res?.errors)
                alert({ title: "Gagal", html: res?.message })
            }
        }
        catch (error) {
            setSuccess(false)
            alert({ title: "Gagal", html: "Gagal daftar, terjadi kesalahan di sisi server." })
        }

        setLoading(false)
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nik || payload?.nik === "") {
            hasError = true
            jsonErrors["nik"] = "NIK harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    useEffect(() => {
        if (submit === true) {
            if (success === true) {
                setPayload({ nik: "" })
            }
        }
    }, [success])

    return <React.Fragment>
        <div className="authentication-inner row m-0">
            <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                <div className="w-100 d-flex justify-content-center">
                    <img src={IlsLogin} className="img-fluid" alt="Login" style={{ maxHeight: "80vh", width: "auto" }} />
                </div>
            </div>

            <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                <div className="w-px-400 mx-auto">
                    <div className="app-brand mb-5">
                        <Link to={process.env.REACT_APP_SUBDIR + "/"} className="app-brand-link gap-2">
                            <span className="app-brand-logo demo">
                                <img src={SvgLogo} alt="Logo" className="logo-app" />
                            </span>
                            <span className="app-brand-text demo text-body fw-bolder main-logo-text">{APP_NAME}</span>
                        </Link>
                    </div>
                    <h4 className="mb-2">Halaman Lupa Password</h4>
                    <p className="mb-4">Kami akan mengirimkan password ke email terdaftar</p>

                    <form onSubmit={onSubmit} className="fv-plugins-bootstrap5">
                        <div className="mb-3">
                            <NumberInput
                                id={"nik"}
                                name={"nik"}
                                placeholder={"Masukkan nik anda"}
                                label={"NIK"}
                                onChange={(e) => onChange('nik', e?.target?.value)}
                                required={true}
                                value={payload?.nik}
                                error={errors?.nik}
                                tabIndex={1}
                            />
                            <br />
                            <button
                                tabIndex={2}
                                type="submit" className="btn btn-primary d-block w-100" disabled={submit === true && loading === true}>
                                Lanjut
                                {submit === true && loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                            </button>
                        </div>
                    </form>

                    <p>
                        <Link to={process.env.REACT_APP_SUBDIR + "/sign-in"} tabIndex={6}>
                            <span>Halaman login</span>
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default ForgetPassword