import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import IlsLogin from "../assets/img/illustrations/ils-login.png"
import SvgLogo from "../assets/svg/logo.svg"
import { pushUrl } from "../utils/url"
import TextInput from "../components/Form/TextInput"
import PasswordInput from "../components/Form/PasswordInput"
import { changeTitle } from "../utils/page"
import { APP_NAME } from "../constants"
import { canForgetPassword, canRemember, canSignUp } from "../utils/config"
import { request } from "../utils/request"
import { alert } from "../utils/alert"

const SignUp = (props) => {
    const [payload, setPayload] = useState({ username: "", name: "", email: "", password: "" })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(null)
    const [success, setSuccess] = useState(null)

    useEffect(() => {
        changeTitle("Masuk")
    }, [])

    const actionSignUp = async (payload) => {
        try {
            const headerPost = {
                "Content-Type": "application/json",
            }
            const res = await request(process.env.REACT_APP_SERVICE_AUTH + '/register', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify(payload)
            })

            if (res?.success === true) {
                setSuccess(true)
                setLoading(false)
            } else {
                setSuccess(false)
                setLoading(false)
                setErrors(res?.errors)
            }
        } catch (error) {
            setSuccess(false)
            setLoading(false)
            alert({ title: "Gagal", html: "Gagal daftar, terjadi kesalahan di sisi server." })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)
        setErrors(null)

        if (validate() === true) {
            setSubmit(true)
            await actionSignUp(payload)
        }
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.username || payload?.username === "") {
            hasError = true
            jsonErrors["username"] = "Username harus diisi"
        }
        if (!payload?.name || payload?.name === "") {
            hasError = true
            jsonErrors["name"] = "Nama harus diisi"
        }
        if (!payload?.email || payload?.email === "") {
            hasError = true
            jsonErrors["email"] = "Email harus diisi"
        }
        if (!payload?.password || payload?.password === "") {
            hasError = true
            jsonErrors["password"] = "Password harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const onChange = ((field, value) => {
        if (field === "username") {
            value = value?.replace(/\s/g, '')?.toLowerCase()
        }

        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    useEffect(async () => {
        if (success === true) {
            await alert({ title: "Berhasil", html: "Berhasil daftar, silahkan masuk ke akun anda." })
            window.location.href = `${process.env.REACT_APP_SUBDIR}/beranda`
        }
    }, [success])

    const onBack = () => {
        pushUrl("/sign-in")
    }

    const [showPassword, setShowPassword] = useState(false)

    return <React.Fragment>
        <div className="authentication-inner row m-0">
            <div className="d-flex col-lg-12 align-items-center authentication-bg p-sm-5 p-4">
                <div className="w-px-500 mx-auto">
                    <div className="app-brand mb-5">
                        <Link to={"/"} className="app-brand-link gap-2">
                            <span className="app-brand-logo demo">
                                <img src={SvgLogo} alt="Logo" className="logo-app" />
                            </span>
                            <span className="app-brand-text demo text-body fw-bolder main-logo-text text-primary">{APP_NAME}</span>
                        </Link>
                    </div>
                    <h4 className="mb-2">Halaman Daftar Pelajar</h4>
                    <p className="mb-4">Isi data berikut untuk mendaftarkan akun Anda</p>

                    <form onSubmit={onSubmit} className="fv-plugins-bootstrap5">
                        <div className="mb-3">
                            <TextInput
                                id={"username"}
                                name={"username"}
                                placeholder={"Masukkan username Anda"}
                                label={"Username"}
                                onChange={(e) => onChange('username', e?.target?.value)}
                                required={true}
                                value={payload?.username}
                                error={errors?.username}
                                tabIndex={1}
                            />

                            <TextInput
                                id={"name"}
                                name={"name"}
                                placeholder={"Masukkan nama Anda"}
                                label={"Nama"}
                                onChange={(e) => onChange('name', e?.target?.value)}
                                required={true}
                                value={payload?.name}
                                error={errors?.name}
                                tabIndex={2}
                            />

                            <TextInput
                                id={"email"}
                                name={"email"}
                                placeholder={"Masukkan email Anda"}
                                label={"Email"}
                                onChange={(e) => onChange('email', e?.target?.value)}
                                required={true}
                                value={payload?.email}
                                error={errors?.email}
                                tabIndex={3}
                            />

                            <PasswordInput
                                id={"password"}
                                name={"password"}
                                placeholder={"Masukkan password Anda"}
                                label={"Password"}
                                onChange={(e) => onChange('password', e?.target?.value)}
                                required={true}
                                value={payload?.password}
                                error={errors?.password}
                                canShow={true}
                                showPassword={showPassword}
                                setShowPassword={() => setShowPassword(!showPassword)}
                                tabIndex={4}
                            />

                            <div className="col-12 d-flex justify-content-between mt-4">
                                <button type="button" onClick={onBack} className="btn btn-label-secondary btn-prev">
                                    Kembali
                                </button>
                                <button
                                    tabIndex={5}
                                    type="submit" className="btn btn-primary btn-next" disabled={submit === true && loading === true}>
                                    Sign up
                                    {submit === true && loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default (SignUp)