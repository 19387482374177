import actionTypes from './actionTypes'

export const actionSignIn = (data) => {
  return {
    type: actionTypes.SIGN_IN,
    data
  }
}

export const actionSignInStart = () => {
  return {
    type: actionTypes.SIGN_IN_START,
  }
}

export const actionSignInEnd = () => {
  return {
    type: actionTypes.SIGN_IN_END,
  }
}

export const actionSignInSuccess = (data) => {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
    data,
  }
}

export const actionSignInFailed = (data) => {
  return {
    type: actionTypes.SIGN_IN_FAILED,
    data,
  }
}

export const setExpired = (data) => {
  return {
    type: actionTypes.SET_EXPIRED,
    data
  }
}

export const actionRefreshToken = (data) => {
  return {
    type: actionTypes.REFRESH_TOKEN,
    data
  }
}

export const actionRefreshTokenSuccess = (data) => {
  return {
    type: actionTypes.REFRESH_TOKEN_SUCCESS,
    data
  }
}

export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
  }
}

export const actionSetPelajar = (data) => {
  return {
    type: actionTypes.SET_PELAJAR,
    data
  }
}

export const actionSetUser = (data) => {
  return {
    type: actionTypes.SET_USER,
    data
  }
}