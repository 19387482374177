import React, { useEffect, useState, useRef } from 'react'

const VideoPlayer = ({ url, fragmented = false, track = false, setCurrentTime = null, setTotalTime = null }) => {
    const [mediaSource] = useState(new MediaSource())
    const videoStreamer = useRef(null)

    useEffect(async () => {
        const vidElement = videoStreamer.current

        if (vidElement) {
            if (fragmented === true) {
                var mime = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'

                if ('MediaSource' in window && MediaSource.isTypeSupported(mime)) {
                    vidElement.src = URL.createObjectURL(mediaSource)

                    console.log({ mediaSource })
                    mediaSource.addEventListener('sourceopen', () => {
                        var sourceBuffer = mediaSource.addSourceBuffer(mime)

                        fetch(url)
                            .then((response) => {
                                console.log({ response })
                                return response.arrayBuffer();
                            })
                            .then((arrayBuffer) => {
                                console.log({ arrayBuffer })
                                sourceBuffer.addEventListener('updateend', function (e) {
                                    if (!sourceBuffer.updating && mediaSource.readyState === 'open') {
                                        mediaSource.endOfStream()
                                    }
                                });
                                sourceBuffer.appendBuffer(arrayBuffer)
                            })

                    })
                }
            } else {
                console.log("not fragmented")
                vidElement.src = url

                if (track === true) {
                    let watched = []

                    vidElement.addEventListener('loadedmetadata', () => {
                        let duration = parseInt(Math.ceil(vidElement.duration * 1) / 1)

                        if (setTotalTime !== null)
                            setTotalTime(duration)
                    })

                    vidElement.addEventListener('timeupdate', () => {
                        let currentTime = parseInt(vidElement?.currentTime)
                        if (currentTime > 0) {
                            watched[currentTime] = 1
                            let sum = watched.reduce((total, val) => { return total + val; }, 0)

                            setCurrentTime(sum)
                        }
                    })

                    vidElement.addEventListener('ended', () => {
                        setCurrentTime(-1)
                    })
                }
            }
            io.observe(vidElement)
        }
    }, [url])

    const io = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                entry.target.pause()
            }
        });
    })

    return <React.Fragment>

        <video
            ref={videoStreamer}
            type="video/mp4"
            label="Label"
            autoPlay={false}
            loop={false}
            style={{ width: "100%", height: "100%" }}
            controls={true}
        />
    </React.Fragment>
}

export default VideoPlayer