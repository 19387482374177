import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { asTimeWithLabel } from "../../../utils/formatter"
import { alert } from "../../../utils/alert"
import { changeTitle } from "../../../utils/page"
import { request } from "../../../utils/request"

const KursusPertemuanQuizAwal = (props) => {
    const { accessToken, match: { params: { id, pertemuanId } } } = props

    const [payload, setPayload] = useState([])

    const [error, setError] = useState(null)
    const [soal, setSoal] = useState(null)
    const [jawaban, setJawaban] = useState(null)
    const [opsiJawaban, setOpsiJawaban] = useState(null)
    const [menuShow, setMenuShow] = useState(false)
    const [nomorSekarang, setNomorSekarang] = useState(null)
    const [selesai, setSelesai] = useState(null)

    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(null)

    const [selisihWaktu, setSelisihWaktu] = useState(null)
    const [selisihWaktuLoaded, setSelisihWaktuLoaded] = useState(false)
    const [fetchLoaded, setFetchLoaded] = useState(false)

    const [data, setData] = useState(null)
    const [waktuQuiz, setWaktuQuiz] = useState(null)

    useEffect(async () => {
        let titlePage = "Proses Tes"
        changeTitle(titlePage)

        await actionFetchUjian()
        await actionGetWaktuServer()

        window.addEventListener("resize", checkSize)

        return () => window.removeEventListener("resize", checkSize)
    }, [])

    const checkSize = () => {
        if (window.innerWidth > window.innerHeight) {
            if (menuShow === true) {
                setMenuShow(false)
            }
        }
    }

    useEffect(() => {
        window.addEventListener("resize", checkSize)

        return () => window.removeEventListener("resize", checkSize)
    }, [menuShow])

    const actionGetWaktuServer = async () => {
        setSelisihWaktuLoaded(false)

        let now = parseInt((new Date).getTime() / 1000)
        try {
            const res = await request(process.env.REACT_APP_SERVICE_TES + '/waktu-server', {
                method: 'GET'
            })

            if (res?.success === true) {
                setSelisihWaktu(res?.result - now)
                setSelisihWaktuLoaded(true)
            } else {
                setSelisihWaktu(0)
                setSelisihWaktuLoaded(true)
            }
        } catch (error) {
            setSelisihWaktu(0)
            setSelisihWaktuLoaded(true)
        }
    }

    const actionFetchUjian = async () => {
        setFetchLoaded(true)

        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }

            console.log({ header })

            const res = await request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/fetch-quiz-awal?pertemuan_id=' + pertemuanId, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                console.log({ res })
                setData(res?.result?.quizAwal)
                setNomorSekarang(1)
                setWaktuQuiz(res?.result?.sisaWaktu)
                setSelesai(res?.result?.selesai)

                if (res?.result?.selesai === true) {
                    await alert({ title: "Gagal", html: "Quiz telah berakhir." })
                    window.location.href = `${process.env.REACT_APP_SUBDIR}/kursus/detail/${id}/pertemuan/${pertemuanId}`
                }
            } else {
                alert({ title: "Gagal", html: "Gagal meminta data ke server." })
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server." })
        }
    }

    useEffect(() => {
        console.log({ data })
        if (nomorSekarang && data) {
            setSoal(data[nomorSekarang - 1])
            setOpsiJawaban(data[nomorSekarang - 1]?.jawaban)
        }
    }, [nomorSekarang, data])

    const viewFooter = () => {
        return <React.Fragment>
            <button className="btn btn-primary" type="button" onClick={() => clickSimpanJawaban()} tabIndex={6} disabled={(submit === true && loading === true) || waktuQuiz <= 0}>
                Simpan
                {submit === true && loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
            </button>
        </React.Fragment>
    }

    const actionKirimJawabanUjian = async () => {
        setSubmit(true)
        setLoading(true)

        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_PERTEMUAN + '/submit-quiz-awal?pertemuan_id=' + pertemuanId, {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify({ "data": payload })
            })

            if (res?.success === true) {
                await alert({ title: "Berhasil", html: "Berhasil kirim data.", icon: "success" })

                window.location.href = `${process.env.REACT_APP_SUBDIR}/kursus/detail/${id}/pertemuan/${pertemuanId}`
            } else {
                setLoading(false)
                alert({ title: "Gagal", html: "Gagal kirim data, terjadi kesalahan di sisi server." })
            }
        } catch (error) {
            setLoading(false)
            alert({ title: "Gagal", html: "Gagal kirim data, terjadi kesalahan di sisi server." })
        }
    }

    const clickSimpanJawaban = () => {
        if (jawaban) {
            if (nomorSekarang < data?.length) {
                setNomorSekarang(nomorSekarang + 1)
            } else {
                actionKirimJawabanUjian()
            }
        } else {
            setError("Jawaban belum diisi")
        }
    }

    const pilihJawaban = (jawabanId) => {
        console.log({ jawabanId })
        let _payload = [...payload]
        let existKey = _payload?.findIndex(val => val?.pertanyaanId === soal?.id)
        console.log({ existKey })
        if (existKey >= 0) {
            _payload[existKey] = { pertanyaanId: soal?.id, jawabanId }
        } else {
            _payload.push({ pertanyaanId: soal?.id, jawabanId })
        }
        setPayload(_payload)
        setJawaban(jawabanId)
    }

    useEffect(() => {
        if (waktuQuiz) {
            let intervalId = setInterval(() => setWaktuQuiz(waktuQuiz - 1), 1000)

            return () => clearInterval(intervalId)
        }
    }, [waktuQuiz])

    useEffect(() => {
        if (waktuQuiz != null && waktuQuiz <= 0 && selesai === false) {
            actionKirimJawabanUjian()
        }
    }, [waktuQuiz])

    const viewPertanyaan = () => {
        return <React.Fragment>
            <div className="card mb-4">
                <div className="card-header border-bottom py-3 border-grid-ujian">
                    <small className="text-muted text-uppercase">Nomor : <span className="fw-semibold">{nomorSekarang} / {data?.length}</span></small>
                </div>
                <div className="card-body box-ujian pt-4">
                    <span dangerouslySetInnerHTML={{ __html: soal?.pertanyaan }}></span>

                    {
                        opsiJawaban?.map((val, key) => {
                            return <React.Fragment key={key}>
                                <div className={"form-check " + ((key + 1) < opsiJawaban?.length ? "mb-2" : "")}>
                                    <input name={"soal" + soal?.id} value={val?.id} className="form-check-input" type="radio" id={"jawaban" + val?.id} tabIndex={key + 1} checked={jawaban === val?.id} onChange={(e) => pilihJawaban(e?.target?.value)} />
                                    <label className="form-check-label" htmlFor={"jawaban" + val?.id}>
                                        <span className="me-2">{val?.kode ? "[" + val?.kode + "]" : ""}</span> {val?.jawaban}
                                    </label>
                                </div>
                            </React.Fragment>
                        })
                    }

                    {
                        error ?
                            <p className="text-danger mb-0 mt-4">{error}</p>
                            : null
                    }
                </div>
            </div>
        </React.Fragment>
    }

    const viewNavbar = () => {
        return <React.Fragment>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme nav-mw-ujian" id="layout-navbar">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <div className="navbar-nav align-items-center">
                        <div className="nav-item navbar-search-wrapper mb-0">
                            <div className="nav-item mb-0 d-block">
                                <small className="text-uppercase">Sisa Waktu :  <span className="fw-semibold">{asTimeWithLabel(waktuQuiz) || "-"}</span></small>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="layout-page">
            {
                selesai === false ?
                    <React.Fragment>
                        {viewNavbar()}

                        <div className="content-wrapper">
                            <div className={menuShow === true ? "d-none" : "d-block"}>
                                <div className="container-xxl flex-grow-1 container-p-y container-mw-ujian">
                                    {
                                        selisihWaktuLoaded === true && fetchLoaded === true ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {viewPertanyaan()}
                                                    <div className="d-none d-md-block">
                                                        <div className="card mb-4">
                                                            {viewFooter()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="auth-scan-cam-wrapper">
                                                <span className="spinner-border ms-2" role="status" aria-hidden="true"></span>
                                            </div>
                                    }
                                </div>
                            </div>

                            <div className="content-backdrop fade"></div>
                        </div>
                    </React.Fragment>
                    :
                    <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme nav-mw-ujian" id="layout-navbar">
                        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                            <div className="navbar-nav align-items-center">
                                <div className="nav-item navbar-search-wrapper mb-0">
                                    <div className="nav-item mb-0 d-block">
                                        <small className="text-uppercase">Quiz selesai</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
            }
        </div>
    </React.Fragment>
}


const mapStateToProps = state => {
    const { accessToken } = state.auth

    return { accessToken }
}

export default withRouter(connect(mapStateToProps, null)(KursusPertemuanQuizAwal))