import { all } from 'redux-saga/effects'
import authSaga from './auth/saga'
import notifikasiSaga from './notifikasi/saga'

import pengajarSaga from './pengajar/saga'
import pelajarSaga from './pelajar/saga'
import kursusSaga from './kursus/saga'
import modulSaga from './modul/saga'
import pertemuanSaga from './pertemuan/saga'
import jobsheetSaga from './jobsheet/saga'
import gambarSaga from './gambar/saga'
import videoSaga from './video/saga'
import laporanSaga from './laporan/saga'
import quizSaga from './quiz/saga'
import kelompokSaga from './kelompok/saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    notifikasiSaga(),

    kursusSaga(),
    jobsheetSaga(),
    gambarSaga(),
    videoSaga(),
    laporanSaga(),
    quizSaga()
  ])
}