import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AuthLayout from "./components/Layout/AuthLayout"
import NonAuthLayout from "./components/Layout/NonAuthLayout"
import ErrorLayout from "./components/Layout/ErrorLayout"
import { useSelector } from 'react-redux'
import Page404 from './pages/Error/Page404'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import ForgetPassword from './pages/ForgetPassword'
import SignOut from './pages/SignOut'
import Beranda from './pages/Beranda'
import UjianLayout from './components/Layout/UjianLayout'
import ProsesTes from './pages/Tes/proses'

import KursusDiikutiIndex from './pages/KursusDiikuti/index'

import KursusIndex from './pages/Kursus/index'
import KursusDetail from './pages/Kursus/detail'
import KursusPertemuanDetail from './pages/Kursus/Pertemuan/detail'
import KursusPertemuanQuiz from './pages/Kursus/Pertemuan/quiz'
import KursusPertemuanQuizAwal from './pages/Kursus/Pertemuan/quizAwal'

const App = ({ childProps }) => {
  return (
    <Switch>
      <Route exact path={"/"} render={() => <Redirect to={process.env.REACT_APP_SUBDIR + '/beranda'} />} />
      <Route exact path={process.env.REACT_APP_SUBDIR + "/"} render={() => <Redirect to={process.env.REACT_APP_SUBDIR + '/beranda'} />} />

      <NonAuthRoute path={process.env.REACT_APP_SUBDIR + "/sign-in"} component={SignIn} props={childProps} />
      <NonAuthRoute path={process.env.REACT_APP_SUBDIR + "/sign-up"} component={SignUp} props={childProps} />
      <NonAuthRoute path={process.env.REACT_APP_SUBDIR + "/forget-password"} component={ForgetPassword} props={childProps} />

      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/beranda"} component={Beranda} props={childProps} />

      <UjianRoute exact path={process.env.REACT_APP_SUBDIR + "/tes"} component={ProsesTes} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/kursus-diikuti"} component={KursusDiikutiIndex} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/kursus"} component={KursusIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kursus/detail/:id"} component={KursusDetail} props={childProps} exact />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kursus/detail/:id/pertemuan/:pertemuanId"} component={KursusPertemuanDetail} props={childProps} exact />
      <QuizRoute path={process.env.REACT_APP_SUBDIR + "/kursus/detail/:id/pertemuan/:pertemuanId/quiz"} component={KursusPertemuanQuiz} props={childProps} exact />
      <QuizRoute path={process.env.REACT_APP_SUBDIR + "/kursus/detail/:id/pertemuan/:pertemuanId/quiz-awal"} component={KursusPertemuanQuizAwal} props={childProps} exact />

      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/sign-out"} component={SignOut} props={childProps} />

      <ErrorRoute path='*' exact={true} component={Page404} />
    </Switch>
  )
}

const ErrorRoute = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <ErrorLayout>
          <Component {...props} />
        </ErrorLayout>
      )}
    />
  )
}

const NonAuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth)
  let pass = false

  if (auth?.user) {
    const now = parseInt((new Date).getTime() / 1000)
    const sisaWaktu = auth?.expiredAt - now

    if (sisaWaktu > 0) {
      pass = true
    }
  }

  return (
    <Route
      {...rest}
      render={props => (
        pass === true ?
          // <NonAuthLayout>
          //   <Component {...props} />
          // </NonAuthLayout>
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + '/beranda',
              state: { from: props.location }
            }}
          />
          :
          <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
      )}
    />
  )
}

const AuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth)
  let pass = false
  let sudahTes = true

  if (auth && auth.user) {
    pass = true
    if (auth?.user?.status === "Belum Tes") {
      sudahTes = false
    }
  }

  return (
    <Route
      {...rest}
      render={props => (
        pass === true ?
          sudahTes === true ?
            <AuthLayout>
              <Component {...props} />
            </AuthLayout>
            :
            <Redirect
              to={{
                pathname: process.env.REACT_APP_SUBDIR + '/tes'
              }} />
          :
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + '/sign-in',
              state: { from: props.location }
            }} />
      )}
    />
  )
}

const QuizRoute = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <UjianLayout>
          <Component {...props} />
        </UjianLayout>
      )}
    />
  )
}

const UjianRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth)
  let sudahTes = true

  if (auth && auth.user) {
    if (auth?.user?.status === "Belum Tes") {
      sudahTes = false
    }
  }

  if (sudahTes === true) {
    window.location.href = `${process.env.REACT_APP_SUBDIR}/beranda`
    return (
      <Route
        {...rest}
        render={null}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={props => (
        <UjianLayout>
          <Component {...props} />
        </UjianLayout>
      )}
    />
  )
}

export default App