import React, { useEffect, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"

import './index.css'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../constants'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

const options = {
    cMapUrl: 'cmaps/',
    standardFontDataUrl: 'standard_fonts/',
}

const PDFViewer = ({ url, singlePage = true, setTotalPage = null, onClickNext = null }) => {
    const [loading, setLoading] = useState(null)
    const [file, setFile] = useState(null)
    const [numPages, setNumPages] = useState(null)
    const [page, setPage] = useState(1)

    useEffect(() => {
        setLoading(true)
        setNumPages(null)
        setPage(1)
        setFile(url)
    }, [url])

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setLoading(false)
        setNumPages(nextNumPages)
        setTotalPage(nextNumPages)
    }

    const clickBack = (e) => {
        setPage(page - 1)
        e.preventDefault()
    }

    const clickNext = (e) => {
        setPage(page + 1)
        onClickNext(page + 1)
        e.preventDefault()
    }

    const view = () => {
        return <React.Fragment>
            {
                singlePage === true ?
                    numPages > 0 ?
                        <div className="row">
                            <div className="col-2 text-center">
                                {
                                    page <= 1 ? <a onClick={(e) => e.preventDefault()}><i className="bx bx-chevron-left fs-2"></i></a>
                                        : <a href="#" onClick={(e) => clickBack(e)} style={{ zIndex: 3 }}><i className="bx bx-chevron-left text-primary fs-2"></i></a>
                                }
                            </div>
                            <div className="col-8">
                                <p className="text-center" style={{ zIndex: 3, position: "relative" }}>
                                    <small className="text-muted text-uppercase">Halaman {page} dari {numPages}</small>
                                </p>
                            </div>
                            <div className="col-2 text-center">
                                {
                                    page >= numPages ? <a onClick={(e) => e.preventDefault()}><i className="bx bx-chevron-right fs-2"></i></a>
                                        : <a href="#" onClick={(e) => clickNext(e)} style={{ zIndex: 3 }}><i className="bx bx-chevron-right text-primary fs-2"></i></a>
                                }
                            </div>
                        </div>
                        : null
                    : null
            }

            <div className="pdf_viewer">
                <div className="pdf_viewer__container">
                    <div className="pdf_viewer__container__document">
                        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options} >
                            {
                                singlePage === true ?
                                    <Page key={`page_${page}`} pageNumber={page} scale={1.0} />
                                    :
                                    Array.from(new Array(numPages), (el, index) => (
                                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                    ))
                            }
                        </Document>
                    </div>
                </div>
            </div>
        </React.Fragment >
    }

    return <React.Fragment>
        {
            loading === true ?
                <p className="text-center mt-3">
                    <span className="spinner-border ms-2" role="status" aria-hidden="true" style={{ borderColor: PRIMARY_COLOR, borderRightColor: SECONDARY_COLOR, width: "1.5rem", height: "1.5rem" }}></span>
                </p>
                : null
        }

        {view()}
    </React.Fragment>
}

export default PDFViewer