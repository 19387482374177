import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import history from '../utils/history'

import auth from "./auth/reducer"
import notifikasi from "./notifikasi/reducer"

import pengajar from "./pengajar/reducer"
import pelajar from "./pelajar/reducer"
import kursus from "./kursus/reducer"
import modul from "./modul/reducer"
import pertemuan from "./pertemuan/reducer"
import jobsheet from "./jobsheet/reducer"
import gambar from "./gambar/reducer"
import video from "./video/reducer"
import laporan from "./laporan/reducer"
import quiz from "./quiz/reducer"
import kelompok from "./kelompok/reducer"

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth,
  notifikasi,

  kursus,
  jobsheet,
  gambar,
  video,
  laporan,
  quiz
})

export default rootReducer
