import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { asTimeWithLabel, strtotime } from "../../utils/formatter"
import { alert, confirm } from "../../utils/alert"
import { changeTitle } from "../../utils/page"
import { request } from "../../utils/request"
import { actionSetUser, actionSetPelajar } from "../../store/auth/actions"
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../constants"

const ProsesTes = (props) => {
    const { accessToken, actionSetUser, actionSetPelajar } = props

    const [payload, setPayload] = useState([])

    const [error, setError] = useState(null)
    const [menuShow, setMenuShow] = useState(false)

    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(null)

    const [fetchLoaded, setFetchLoaded] = useState(false)

    const [data, setData] = useState(null)

    useEffect(async () => {
        let titlePage = "Proses Tes"
        changeTitle(titlePage)

        await actionFetchUjian()

        window.addEventListener("resize", checkSize)

        return () => window.removeEventListener("resize", checkSize)
    }, [])

    const checkSize = () => {
        if (window.innerWidth > window.innerHeight) {
            if (menuShow === true) {
                setMenuShow(false)
            }
        }
    }

    useEffect(() => {
        window.addEventListener("resize", checkSize)

        return () => window.removeEventListener("resize", checkSize)
    }, [menuShow])

    const actionFetchUjian = async () => {
        setFetchLoaded(true)

        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }

            console.log({ header })

            const res = await request(process.env.REACT_APP_SERVICE_TES + '/fetch', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                console.log({ res })
                setData(res?.result)
            } else {
            }
        } catch (error) {
        }
    }

    const onClickKirim = () => {
        actionKirimJawabanUjian()
    }

    const viewFooter = () => {
        return <React.Fragment>
            <button className="btn btn-primary" type="button" onClick={() => onClickKirim()} tabIndex={6} disabled={submit === true && loading === true}>
                Selesai
                {submit === true && loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
            </button>
        </React.Fragment>
    }

    const actionKirimJawabanUjian = async () => {
        setSubmit(true)
        setLoading(true)

        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_TES + '/submit', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify({ "data": payload })
            })

            if (res?.success === true) {
                await alert({ title: "Berhasil", html: "Berhasil kirim data.", icon: "success" })
                actionSetPelajar(res?.result?.pelajar)
                actionSetUser(res?.result?.user) 
            } else {
                setLoading(false)
                alert({ title: "Gagal", html: res?.message || "Gagal kirim data, terjadi kesalahan di sisi server." })
            }
        } catch (error) {
            setLoading(false)
            alert({ title: "Gagal", html: "Gagal kirim data, terjadi kesalahan di sisi server." })
        }
    }

    const pilihJawaban = (soal, jawabanId) => {
        let _payload = [...payload]
        console.log({ soal, jawabanId })
        let existKey = _payload?.findIndex(val => val?.pertanyaanId === soal?.id)
        if (existKey >= 0) {
            // _payload[existKey] = { pertanyaanId: soal?.id, jawabanId } // ganti jawaban
            _payload.splice(existKey, 1) // uncheck
        } else {
            _payload.push({ pertanyaanId: soal?.id, jawabanId })
        }

        setPayload(_payload)
    }

    const isChecked = (soalId, jawabanId) => {
        let existKey = payload?.findIndex(val => val?.pertanyaanId === soalId)
        if (existKey >= 0) {
            return payload[existKey]?.jawabanId === jawabanId
        }

        return false
    }

    const viewPertanyaan = (soal, opsiJawaban, key) => {
        return <React.Fragment key={key}>
            <div className="card mb-4">
                <div className="card-body box-ujian pt-4">
                    <span dangerouslySetInnerHTML={{ __html: soal?.pertanyaan }}></span>

                    {
                        opsiJawaban?.map((val, key) => {
                            return <React.Fragment key={key}>
                                <div className={"form-check " + ((key + 1) < opsiJawaban?.length ? "mb-2" : "")}>
                                    <input name={"soal" + soal?.id} value={val?.id} className="form-check-input" type="radio" id={"jawaban" + val?.id} tabIndex={key + 1} checked={isChecked(soal?.id, val?.id)} onChange={(e) => pilihJawaban(soal, e?.target?.value)} onClick={(e) => isChecked(soal?.id, val?.id) ? pilihJawaban(soal, e?.target?.value) : false} />
                                    <label className="form-check-label" htmlFor={"jawaban" + val?.id}>
                                        {val?.jawaban}
                                    </label>
                                </div>
                            </React.Fragment>
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    }

    const viewNavbar = () => {
        return <React.Fragment>
            <div className="card mb-4" style={{ zIndex: 9999 }}>
                <div className="card-body box-ujian py-3">
                    <small className="text-uppercase">Jumlah Pertanyaan : {data?.length}</small>
                </div>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="layout-page">
            <div className="content-wrapper">
                <div className={menuShow === true ? "d-none" : "d-block"}>
                    <div className="container-xxl flex-grow-1 container-p-y container-mw-ujian">
                        {
                            fetchLoaded === true ?
                                <React.Fragment>
                                    {viewNavbar()}
                                    <div className="alert alert-primary my-4" role="alert">
                                        Isilah pertanyaan berikut jika sesuai dengan yang saudara rasakan
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                data?.map((val, key) => {
                                                    return viewPertanyaan(val, val?.jawaban, key)
                                                })
                                            }
                                            {
                                                error ?
                                                    <p className="text-danger mb-4 mt-4">{error}</p>
                                                    : null
                                            }

                                            <div className="d-none d-md-block">
                                                <div className="card mb-4">
                                                    {viewFooter()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                                :
                                <span className="spinner-border ms-2" role="status" aria-hidden="true" style={{ borderColor: PRIMARY_COLOR, borderRightColor: SECONDARY_COLOR, width: "1.5rem", height: "1.5rem" }}></span>
                        }
                    </div>
                </div>

                <div className="content-backdrop fade"></div>
            </div>
        </div>
    </React.Fragment>
}


const mapStateToProps = state => {
    const { accessToken } = state.auth

    return { accessToken }
}

export default withRouter(connect(mapStateToProps, { actionSetUser, actionSetPelajar })(ProsesTes))