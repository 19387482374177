import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionUpdateVideoEnd, actionUpdateVideoFailed, actionUpdateVideoStart, actionUpdateVideoSuccess, actionCreateVideoEnd, actionCreateVideoFailed, actionCreateVideoStart, actionCreateVideoSuccess, actionFetchVideoEnd, actionFetchVideoStart, actionFetchVideoSuccess, actionDeleteVideoSuccess, actionDeleteVideoFailed, actionDeleteVideoStart, actionDeleteVideoEnd } from './actions'
import { pushUrl } from '../../utils/url'

function* sagaFetchVideo(params) {
  yield put(actionFetchVideoStart())

  const { data: { accessToken, search, page, sort, perPage } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let url = `${process.env.REACT_APP_SERVICE_VIDEO}${pageParam}${searchParam}${sortParam}${perPageParam}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchVideoSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
    // yield put(actionSignInFailed())
  }

  yield put(actionFetchVideoEnd())
}

function* sagaCreateVideo(params) {
  yield put(actionCreateVideoStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_VIDEO}`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionCreateVideoSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionCreateVideoFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal menambah data",
      errors: null
    }

    yield put(actionCreateVideoFailed(data))
  }

  yield put(actionCreateVideoEnd())
}

function* sagaUpdateVideo(params) {
  yield put(actionUpdateVideoStart())

  const { data: { accessToken, payload, id } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_VIDEO}/${id}`
    const res = yield call(request, url, {
      method: 'PUT',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionUpdateVideoSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionUpdateVideoFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal merubah data",
      errors: null
    }

    yield put(actionUpdateVideoFailed(data))
  }

  yield put(actionUpdateVideoEnd())
}

function* sagaDeleteVideo(params) {
  yield put(actionDeleteVideoStart())

  const { data: { accessToken, id } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_VIDEO}/${id}`
    const res = yield call(request, url, {
      method: 'DELETE',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionDeleteVideoSuccess())
    } else {
      yield put(actionDeleteVideoFailed())
    }
  } catch (err) {
    yield put(actionDeleteVideoFailed())
  }

  yield put(actionDeleteVideoEnd())
}

function* videoSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_VIDEO, sagaFetchVideo),
    takeEvery(actionTypes.CREATE_VIDEO, sagaCreateVideo),
    takeEvery(actionTypes.UPDATE_VIDEO, sagaUpdateVideo),
    takeEvery(actionTypes.DELETE_VIDEO, sagaDeleteVideo),
  ])
}

export default videoSaga